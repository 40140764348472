<template>
  <div class="page flex-col border" style="margin: 0 auto;">
    <div class="wrap1 flex-col border">

      <!--导航开始-->
      <div class="outer1 flex-col border">
        <div class="mod1 flex-row">
          <span class="word1">电商生意方舟</span>
          <span class="txt1"><a href="/">首页</a></span>
          <span class="txt2"><a href="/scheme">方案</a></span>
          <span class="word2"><a href="/price">帮助</a></span>
          <span class="word2"><a href="/dataView" style="border-bottom: solid 1px white;height: 51px;width: 72px;">数据全景</a></span>
        </div>
      </div>
      <!--导航结束-->

      <!--中间内容开始-->
      <div class="border" style="width: 100%;height: auto;">
        <!--左侧菜单开始-->
        <div class="border" style="width: 220px;height: auto;float: left;z-index: 100;">
          <template style="float: left;">
            <el-menu
                :default-active="activeIndex"
                class="el-menu-vertical-demo"
                @select="handleSelect"
                :default-openeds="openeds">
              <el-submenu :index="obj.id" v-for="obj in leftData" :key="obj.id">
                <template slot="title">
                  <span>{{obj.id}}</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item  :index="obj.id+'-'+item.id" v-for="item in obj.children" :key="item.id">{{item.id}}</el-menu-item>
                </el-menu-item-group>
              </el-submenu>

            </el-menu>
          </template>
        </div>
        <!--左侧菜单结束-->
        <!-- tab三级模块-->
        <div class="border" style="width: 1030px;height: 1100px;float: left;margin-left: 20px;">
          <template>
            <el-tabs v-if="tabShow">
              <el-tab-pane :label="obj.三级模块名称" :key="clickName+obj.三级模块名称" v-for="(obj) in tabPanes">


                <div class="border" style="width: 100%;height: 455px;z-index: 100;" >
                  <div class="player-container border" style="width: 640px;height: 450px;float: left;">
                    <vueMiniPlayer ref="vueMiniPlayer"  :video="{url:obj.videoUrl}"   :mutex="true"  />
                  </div>

                  <div class="border" style="height: 200px;width:260px;margin-left: 0px;float: left;margin-left: 10px;">
                    <p style="font-size: 13px;font-family: PingFangSC-Semibold;">时间维度:&nbsp;&nbsp;<font color="#8F9BB3">{{ obj.时间维度 }}</font></p>
                    <p style="font-size: 13px;font-family: PingFangSC-Semibold;">数据输出:&nbsp;&nbsp;<font color="#8F9BB3">{{ obj.数据输出 }}</font></p>
                    <p style="font-size: 13px;font-family: PingFangSC-Semibold;">插件使用:&nbsp;&nbsp;<font color="#8F9BB3">{{ obj.插件使用 }}</font></p>
                  </div>

                </div>



                <div style="margin-top: 0px;width: auto;" class="border">
                  <el-tabs>
                    <el-tab-pane label="输出示例"></el-tab-pane>
                  </el-tabs>

                  <el-tag v-for="(down,index) in obj.表格名称" type="success" @click="frontDownload(down)"
                          style="margin-top: 10px;width: 100%;cursor:pointer" :key="index"> {{initFileName(down)}}
                  </el-tag>
                </div>

              </el-tab-pane>
            </el-tabs>
          </template>
        </div>
        <!-- tab三级模块结束 -->
      </div>
      <!--中间内容结束-->


      <!-- 底部二维码开始 -->
      <div class="outer7 flex-col" style="margin-top: 0px;float: left;">
        <div class="box2 flex-col">
          <span class="word6">微信扫码联系，获取一对一专属顾问</span>
          <div class="box3 flex-col">
            <img
                class="img1"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPngd2d1111db80d008fb74586c5897eaacdc45d8dd7c20929501b30c119b055b3a2.png"
            />
          </div>
          <span class="word7">
            ©&nbsp;2017-2021上海云扩信息科技有限公司版权所有沪ICP备17057328号-2沪公网安备&nbsp;31010602002627号
          </span>
        </div>
      </div>
      <!-- 底部二维码结束 -->


    </div>

  </div>
</template>
<script>

import { getJson, getLeftMenu } from '../../js/api/getJsonData'

export default {
  data () {
    return {
      isCollapse: true,
      activeIndex: '生意参谋-作战室',
      openeds: ['生意参谋'],
      tabPanes: [],
      activeName: '0',
      clickName: '',
      tabShow: false,
      downUrls: [],
      datas:[],
      leftData:[]
    }
  },
  components: {},
  methods: {
    //左侧菜单的点击回调事件
    handleSelect (key, keyPath) {
      console.log(key)
      this.menuSelect(keyPath[1])
    },
    // 这个方法通过递归找到滚动的元素,用于置顶页面
    getScrollParent (node) {
      if (node == null) {
        return null
      }
      if (node.scrollHeight > node.clientHeight) {
        return node
      } else {
        return this.getScrollParent(node.parentNode)
      }
    },
    menuSelect(e){
      this.clickName = e
      if(this.datas[e]!=undefined && this.datas[e].length>0){
        for (let i = 0; i < this.datas[e]; i++) {
          if(this.datas[e].表格名称!=undefined && this.datas[e].表格名称.length>0){
            for (let j= 0; j < this.datas[e][i].表格名称.length; j++) {
              this.datas[e].表格名称[j].txt=this.initFileName(this.datas[e][i].表格名称[j])
            }
          }
        }

      }
      this.tabPanes = this.datas[e]
      this.tabShow = false
      this.$nextTick(() => {
            this.tabShow = true
          },
      )
    },
    //去除日期
     initFileName(fileName){
        let  lastIndex=fileName.indexOf('.')
        let  firstIndex=fileName.lastIndexOf('#')
        let	 substring=fileName.substring(firstIndex,lastIndex)
        return fileName.replace(substring,'')
     },
    // 文件下载
    frontDownload (fileName) {
      this.$notify({
        message: '【' + fileName + '】正在下载中....',
        type: 'success',
        duration: 2000
      })
      console.log("fileName->"+fileName)
      //fileName=fileName.replace('#','%23')
      fileName=encodeURIComponent(fileName)
      setTimeout(function () {
        var a = document.createElement('a') //创建一个<a></a>标签
        a.href = 'https://application123456.oss-cn-beijing.aliyuncs.com/encoo_excel/excel1116/' + fileName // 给a标签的href属性值加上地址，注意，这里是绝对路径，不用加 点.
        a.download = fileName //设置下载文件文件名，这里加上.xlsx指定文件类型，pdf文件就指定.fpd即可
        a.style.display = 'none' // 障眼法藏起来a标签
        document.body.appendChild(a) // 将a标签追加到文档对象中
        a.click() // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
        a.remove() // 一次性的，用完就删除a标签
      }, 1000)

    },
    // 获取参数列表
    getQueryString (key) {
      var reg = new RegExp('(^|&)' + key + '=([^&]*)(&|$)')
      var result = window.location.search.substr(1).match(reg)
      if (result != null) {
        return decodeURIComponent(result[2])
      } else {
        return ''
      }

    }
  },
  mounted () {
    //&nbsp;切换页面时滚动条自动滚动到顶部
    window.scrollTo(0, 0)
    document.body.scrollTop = document.documentElement.scrollTop = 0
    const element = this.getScrollParent(this.$el)
    element.scrollTop = 0
    //this.initCanvas()
    //设置样式
    for (let i = 0; i < document.getElementsByClassName('el-menu-item-group__title').length; i++) {
      document.getElementsByClassName('el-menu-item-group__title')[i].style.padding = '0px'
    }
  },
  computed: {},
  async created () {
    //加载左侧菜单
    await getLeftMenu().then(response => {
      this.leftData = response.data
      console.log(this.leftData)
    })
   await getJson().then(response=>{
      this.datas=response.data
    })
    let activeIndexParm = this.getQueryString('type')
    if (activeIndexParm != null && activeIndexParm != '') {
      this.activeIndex = activeIndexParm
      this.openeds = [activeIndexParm.split('-')[0]]
      this.menuSelect(activeIndexParm)
    } else {
      this.menuSelect(this.activeIndex)
    }

  }
}
</script>
<style scoped lang="css" src="./assets/index.css"/>
