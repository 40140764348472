<template>
  <div class="page flex-col" style="margin: 0 auto;">
    <div class="layer1 flex-row">
      <div class="bd1 flex-col">
        <div class="section1 flex-col">
          <div class="main1 flex-col">
            <div class="main2 flex-row">
              <img
                class="icon1"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng2d6d1e7ce31371dd15d850e804d3590f9a9072607f2f938ba12ce8adcd3040cc.png"
              />
              <span class="txt1">生意参谋</span>
              <div class="wrap1 flex-col"></div>
            </div>
          </div>
          <div class="main3 flex-col"><span class="word1">作战室</span></div>
          <div class="main4 flex-col">
            <div class="mod1 flex-row">
              <div class="bd2 flex-col"></div>
              <span class="info1">竞争</span>
            </div>
          </div>
          <div class="main5 flex-col"><span class="txt2">流量</span></div>
          <div class="main6 flex-col"><span class="word2">品类</span></div>
          <div class="main7 flex-col"><span class="info2">内容</span></div>
          <div class="main8 flex-col"><span class="txt3">市场</span></div>
          <div class="main9 flex-col"><span class="info3">直播</span></div>
          <div class="main10 flex-col"><span class="info4">自助分析</span></div>
          <div class="main11 flex-col">
            <div class="bd3 flex-row">
              <div class="wrap2 flex-col"></div>
              <span class="word3">超级推荐</span>
              <div class="wrap3 flex-col"></div>
            </div>
            <img
              class="icon2"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng121142f837d3e02c201589dfedc23d8fec44961a330740ca3c1f848b12455446.png"
            />
          </div>
          <div class="main12 flex-col"><span class="info5">账户</span></div>
          <div class="main13 flex-col"><span class="word4">推广报表</span></div>
          <div class="main14 flex-col">
            <div class="block1 flex-row">
              <img
                class="label1"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPngc37fcf2ed3ec827bc01f5b23552fcab8feb5aad186ec425f188995767eb3bec0.png"
              />
              <span class="txt4">超级钻展</span>
              <div class="group1 flex-col"></div>
            </div>
          </div>
          <div class="main15 flex-col"><span class="info6">账户</span></div>
          <div class="main16 flex-col"><span class="word5">批量报表</span></div>
          <div class="main17 flex-col">
            <div class="block2 flex-row">
              <img
                class="label2"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng86a8b2049018f9bf9d31d7a0d400a7eaa25870fa2dd234f2807bc697793e7132.png"
              />
              <span class="info7">品销宝</span>
              <div class="bd4 flex-col"></div>
            </div>
          </div>
          <div class="main18 flex-col"><span class="word6">账户</span></div>
          <div class="main19 flex-col"><span class="info8">报表</span></div>
          <div class="main20 flex-col">
            <div class="bd5 flex-row">
              <img
                class="icon3"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng5802cf563406f07c277bcaebfcbd2c623afc1233cecaa070974c0f17607a9271.png"
              />
              <span class="txt5">淘宝客</span>
              <div class="box1 flex-col"></div>
            </div>
          </div>
          <div class="main21 flex-col"><span class="txt6">账户</span></div>
          <div class="main22 flex-col"><span class="txt7">效果报表</span></div>
          <div class="main23 flex-col">
            <div class="main24 flex-row">
              <img
                class="label3"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng15c5fce0abcfecfbc4a55543cdab7e4ccc8a2c2a1869227186dec7e4123cc4d3.png"
              />
              <span class="word7">天猫直通车</span>
              <div class="bd6 flex-col"></div>
            </div>
          </div>
          <div class="main25 flex-col"><span class="word8">账户</span></div>
          <div class="main26 flex-col"><span class="txt8">报表</span></div>
          <div class="main27 flex-col">
            <div class="group2 flex-row">
              <img
                class="label4"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPngf389bbd2f611930e7891b64c69b7b8e699c64029efb6f2c4914568ebcff9904d.png"
              />
              <span class="word9">万相台</span>
              <div class="section2 flex-col"></div>
            </div>
          </div>
          <div class="main28 flex-col"><span class="txt9">推广计划</span></div>
          <div class="main29 flex-col"><span class="info9">超级直播(定向)</span></div>
        </div>
      </div>
      <div class="bd7 flex-col">
        <div class="group3 flex-col">
          <div class="box2 flex-row">
            <span class="word10">监控店铺</span>
            <span class="word11">竞品分析</span>
            <span class="word12">监控商品</span>
            <span class="txt10">品牌分析</span>
            <span class="info10">竞品分析</span>
            <span class="txt11">监控品牌</span>
          </div>
          <div class="box3 flex-col"></div>
          <div class="box4 flex-row">
            <div class="outer1 flex-col">
              <img
                class="img1"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng9791c319825c646f5ebe42fce6389d544e177fbad6cedd772ef41b48cb19e235.png"
              />
            </div>
            <div class="outer2 flex-col">
              <span class="txt12">时间维度：</span>
              <span class="txt13">采集估时：</span>
              <span class="info11">数据维度：</span>
              <span class="word13">数据输出：</span>
              <span class="word14">插件使用：</span>
              <span class="txt14">输出表格数量：</span>
              <span class="word15">表格内容：</span>
            </div>
            <div class="outer3 flex-col">
              <span class="word16">年.月.日</span>
              <span class="txt15">约2.5小时</span>
              <span class="word17">入店来源，交易过程</span>
              <span class="word18">Excel，数据库</span>
              <span class="info12">否</span>
              <span class="txt16">6</span>
              <span class="word19">
                这里是表格内容描述这里是表格内容描述这里是表格内容描述这里是表格内容描述这里是表格内容描述这里是表格内容描述。
              </span>
            </div>
          </div>
          <div class="box5 flex-row">
            <div class="group4 flex-col"></div>
            <img
              class="icon4"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng213ec586e7529eca3e0287c9c3550be5d6fe2995b54e7361b5fb9ba2d9de7454.png"
            />
            <div class="group5 flex-col"></div>
          </div>
          <span class="word20">表格参数示例</span>
          <div class="box6 flex-col">
            <div class="box7 flex-row">
              <div class="layer2 flex-col">
                <div class="block3 flex-col">
                  <span class="info13">序号</span>
                  <span class="word21">001</span>
                  <span class="txt17">002</span>
                  <span class="word22">003</span>
                  <span class="word23">004</span>
                  <span class="word24">005</span>
                  <span class="txt18">006</span>
                  <span class="word25">007</span>
                  <span class="txt19">008</span>
                </div>
              </div>
              <div class="layer3 flex-col">
                <div class="bd8 flex-col">
                  <span class="txt20">字段名称</span>
                  <span class="word26">tech&nbsp;ui_account_id</span>
                  <span class="word27">tech&nbsp;ui_account_id</span>
                  <span class="txt21">tech&nbsp;ui_account_id</span>
                  <span class="word28">tech&nbsp;ui_account_id</span>
                  <span class="word29">tech&nbsp;ui_account_id</span>
                  <span class="info14">tech&nbsp;ui_account_id</span>
                  <span class="word30">tech&nbsp;ui_account_id</span>
                  <span class="word31">tech&nbsp;ui_account_id</span>
                </div>
              </div>
              <div class="layer4 flex-col">
                <div class="box8 flex-col">
                  <span class="info15">字段类型</span>
                  <span class="info16">bright</span>
                  <span class="word32">bright</span>
                  <span class="info17">bright</span>
                  <span class="info18">bright</span>
                  <span class="word33">bright</span>
                  <span class="info19">bright</span>
                  <span class="info20">bright</span>
                  <span class="word34">bright</span>
                </div>
              </div>
              <div class="layer5 flex-col">
                <div class="box9 flex-col">
                  <span class="info21">字段描述</span>
                  <span class="txt22">这是一段描述，这是一段描述，这是一段描述，超过字数用…</span>
                  <span class="info22">这是一段描述，这是一段描述，这是一段描述，超过字数用…</span>
                  <span class="info23">这是一段描述，这是一段描述，这是一段描述，超过字数用…</span>
                  <span class="word35">这是一段描述，这是一段描述，这是一段描述，超过字数用…</span>
                  <span class="word36">这是一段描述，这是一段描述，这是一段描述，超过字数用…</span>
                  <span class="txt23">这是一段描述，这是一段描述，这是一段描述，超过字数用…</span>
                  <span class="word37">这是一段描述，这是一段描述，这是一段描述，超过字数用…</span>
                  <span class="info24">这是一段描述，这是一段描述，这是一段描述，超过字数用…</span>
                </div>
              </div>
              <div class="layer6 flex-col">
                <div class="layer7 flex-col">
                  <span class="word38">字段类型</span>
                  <span class="txt24">81</span>
                  <span class="word39">81</span>
                  <span class="txt25">81</span>
                  <span class="txt26">81</span>
                  <span class="txt27">81</span>
                  <span class="txt28">81</span>
                  <span class="info25">81</span>
                  <span class="txt29">81</span>
                </div>
              </div>
            </div>
            <div class="box10 flex-col"></div>
          </div>
          <div class="box11 flex-col"><span class="txt30">下载Excel模板</span></div>
        </div>
        <div class="group6 flex-col"></div>
        <span class="word40">申请试用</span>
      </div>
    </div>
    <div class="layer8 flex-col">
      <div class="bd9 flex-row">
        <div class="outer4 flex-col">
          <img
            class="label5"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPngaaf61b6a8351cd6dd4cdf14c95b6a4d0aec550faa7ef23dd5567a4263e0f1986.png"
          />
        </div>
        <span class="word41">电商数据集合平台</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {}
    };
  },
  methods: {}
};
</script>
<style scoped lang="css" src="./assets/index.css" />
