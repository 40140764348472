import request from '@/js/request'

export function queryFlow (query) {
  return request({
    url: '/flow/queryFlow',
    method: 'get',
    params: query
  })
}

export function queryFlowInfo (query) {
  return request({
    url: '/flow/queryFlowInfo',
    method: 'get',
    params: query
  })
}

