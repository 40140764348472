<template>

  <div class="section1 flex-col">
    <div class="main1 flex-row"><span class="word1">请微信扫码联系，获取一对一专属顾问</span></div>
    <div class="main2 flex-row">
      <div class="group1 flex-col">
        <img
            class="img1"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng341b35607d45a3742301c78064a1967406e8a5bac91cbecb119e61601a1b0b5a.png"
        />
      </div>
    </div>
    <div class="main3 flex-row">
      <img
          class="icon1"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng7040c644c1c9f8773051c1a3d1923adaf0c4756d79b8784e0a12038fcebc71be.png"
      />
    </div>
    <div class="main4 flex-row">
      <img
          class="label1"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng10725eeedca6fd6e0e39a0682b91501a24da67f55bbe25091778d007a660c09b.png"
      />
    </div>
  </div>

</template>
<script>
export default {
  data () {
    return {
      constants: {}
    }
  },
  methods: {}
}
</script>
<style scoped lang="css" src="./assets/index.css"/>
