<template>
  <div class="page flex-col" style="margin: 0 auto;">
    <div class="outer1 flex-col">
      <div class="box1 flex-col">
        <div class="layer1 flex-col">
          <div class="bd1 flex-row">
            <span class="word1">电商生意方舟</span>
            <span class="info1"><a href="/">首页</a></span>
            <span class="word2"><a href="/scheme" style="border-bottom: solid 1px white;height: 51px;">方案</a></span>
            <span class="info2"><a href="/price">帮助</a></span>
            <span class="info2"><a href="/dataView">数据全景</a></span>
          </div>
        </div>
        <div class="layer2 flex-col">
          <div class="section1 flex-row"><span class="txt1">部署架构</span></div>
          <div class="section2 flex-row">
            <div class="box2 flex-col">
              <div class="box3 flex-col">
                <div class="bd2 flex-col"><span class="word3">平台层</span></div>
              </div>
              <div class="box4 flex-col">
                <div class="section3 flex-col"><span class="info3">展现层</span></div>
              </div>
              <div class="box5 flex-col">
                <div class="section4 flex-col"><span class="word4">数据层</span></div>
              </div>
              <div class="box6 flex-col">
                <div class="section5 flex-col"><span class="info4">服务层</span></div>
              </div>
              <div class="box7 flex-col"><span class="word5">运行环境</span></div>
            </div>
            <div class="box8 flex-col">
              <div class="group1 flex-col" style="padding-right:370px;"><span class="word6">电商数据整合平台</span></div>
              <div class="group2 flex-row">
                <div class="outer2 flex-col">
                  <div class="mod1 flex-col">
                    <div class="block1 flex-row">
                      <div class="main1 flex-col"><span class="txt2">系统设置</span></div>
                      <div class="main2 flex-col"><span class="txt3">店铺管理</span></div>
                      <div class="main3 flex-col"><span class="txt4">模块管理</span></div>
                      <div class="main4 flex-col"><span class="word7">系统日志</span></div>
                      <div class="main5 flex-col"><span class="word8">编排管理</span></div>
                    </div>
                  </div>
                  <div class="mod2 flex-col">
                    <div class="block2 flex-row">
                      <div class="box9 flex-row">
                        <div class="layer3 flex-col"><span class="word9">Excel</span></div>
                        <div class="layer4 flex-col"><span class="word10">文件服务</span></div>
                        <div class="layer5 flex-col"><span class="word11">Mysql</span></div>
                        <div class="layer6 flex-col"><span class="word12">钉钉</span></div>
                        <div class="layer7 flex-col"><span class="info5">企业微信</span></div>
                      </div>
                      <div class="box10 flex-col"><span class="txt5">邮件</span></div>
                    </div>
                  </div>
                  <div class="mod3 flex-col">
                    <div class="box11 flex-row">
                      <div class="wrap1 flex-col"><span class="word13">任务队列</span></div>
                      <div class="wrap2 flex-col"><span class="word14">存储服务</span></div>
                      <div class="wrap3 flex-col"><span class="word15">API服务</span></div>
                      <div class="wrap4 flex-col"><span class="txt6">邮件服务</span></div>
                    </div>
                  </div>
                </div>
                <div class="outer3 flex-col">
                  <div class="box12 flex-col">
                    <span class="infoBox1">
                      日
                      <br />
                      志
                      <br />
                      监
                      <br />
                      控
                    </span>
                  </div>
                </div>
                <div class="outer4 flex-col">
                  <div class="box13 flex-col">
                    <span class="infoBox2">
                      权
                      <br />
                      限
                      <br />
                      管
                      <br />
                      理
                    </span>
                  </div>
                </div>
              </div>
              <div class="group3 flex-row">
                <div class="mod4 flex-col"><span class="word16">本地服务器</span></div>
                <div class="mod5 flex-col"><span class="info6">外部服务器</span></div>
                <div class="mod6 flex-col"><span class="word17">第三方虚拟主机</span></div>
              </div>
            </div>
            <div class="box14 flex-col">
              <div class="main6 flex-col">
                <span class="paragraph1">
                  控
                  <br />
                  制
                  <br />
                  台
                </span>
              </div>
            </div>
          </div>
          <div class="section6 flex-col"></div>
          <div class="section7 flex-col"></div>
        </div>
        <div class="layer8 flex-col">
          <div class="group4 flex-row">
            <div class="layer9 flex-col">
              <div class="layer10 flex-col">
                <span class="info7">服务器</span>
                <span class="word18">硬件要求</span>
                <span class="info8">软件要求</span>
              </div>
            </div>
            <div class="layer11 flex-col">
              <span class="info9" style="font-size: 16px;">支持阿里云、腾讯云、Azure及企业内部虚拟化环境</span>
              <span class="infoBox3" style="font-size: 16px;">
                四核2.4GHz或更快CPU
                <br />
                8GB&nbsp;RAM&nbsp;内存
                <br />
                10HDD/SSD&nbsp;GB以上空闲磁盘空间
              </span>
              <span class="word19" style="font-size: 16px;">操作系统：Windows&nbsp;Server&nbsp;2016（x64）</span>
              <div class="bd3 flex-col"></div>
              <span class="paragraph2" style="font-size: 16px;">
                其他依赖：.NET&nbsp;Framework&nbsp;4.6.1或更高
                <br />
                VC++&nbsp;Redistributable&nbsp;2005-2017（中间所有版本）
              </span>
              <div class="bd4 flex-col" ></div>
              <span class="word20" style="font-size: 16px;">网页浏览器：Google&nbsp;Chrome（谷歌浏览器）60或更高</span>
            </div>
          </div>
          <div class="group5 flex-col"></div>
          <div class="group6 flex-col"></div>
          <div class="group7 flex-col"></div>
        </div>
        <div class="layer12 flex-col">
          <div class="mod7 flex-col">
            <span class="word21">微信扫码联系，获取一对一专属顾问</span>
            <div class="layer13 flex-col">
              <img
                class="img1"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPngd2d1111db80d008fb74586c5897eaacdc45d8dd7c20929501b30c119b055b3a2.png"
              />
            </div>
            <span class="txt7">
              ©&nbsp;2017-2021上海云扩信息科技有限公司版权所有沪ICP备17057328号-2沪公网安备&nbsp;31010602002627号
            </span>
          </div>
        </div>
      </div>
      <div class="box15 flex-col">
        <div class="layer14 flex-col">
          <div class="box16 flex-col"><span class="word22">通用说明</span></div>
          <div class="box17 flex-col" style="border-right: 4px solid rgba(235, 235, 235, 1)"><span class="txt8">单店铺采集</span></div>
          <div class="box18 flex-col" style="border-right: 4px solid rgba(235, 235, 235, 1)">
            <span class="word23">支持本机、外部服务器、云端服务器及企业内部虚拟环境</span>
          </div>
          <div class="box19 flex-col" style="border-right: 4px solid rgba(235, 235, 235, 1)">
            <div class="wrap5 flex-row" >
              <span class="txt9">第三方插件</span>
              <span class="word24">仅限阿明工具（备注：市场/竞争不使用插件采集数据）</span>
            </div>
          </div>
          <div class="box20 flex-col" style="border-right: 4px solid rgba(235, 235, 235, 1)"><span class="txt10">采集时长</span></div>
          <div class="box21 flex-col" style="border-right: 4px solid rgba(235, 235, 235, 1)">
            <div class="outer5 flex-row">
              <span class="info10">采集类目</span>
              <span class="word25">建议数量不超过15个，推荐一级类目采集</span>
            </div>
          </div>
          <div class="box22 flex-col" style="border-bottom: 4px solid rgba(235, 235, 235, 1);border-right: 4px solid rgba(235, 235, 235, 1)">
            <div class="box23 flex-row">
              <span class="word26">采集时间段</span>
              <span class="info11">建议：7:00-18:00</span>
            </div>
          </div>
          <div class="box24 flex-row">
            <img
              class="icon1"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPnga322d1e2923d7f302d54a19f9c28af1f4bc2c3437ba5f9a09c2fb47e57d7899d.png"
            />
            <div class="block3">
              <span class="paragraph3">
                声明：
                <br />
              </span>
              <span class="word27">
                甲方客户需要根据我们的建议和指导来设置采集频率和类目数量，不然可能会被反扒警告、罚分等处罚。历史数据采集也需要按照指导来特殊设置。&nbsp;为保障甲方系统及业务正常，若甲方人员服务请求涉及威胁系统安全或影响甲方业务运转，乙方有权向甲方给出警示提醒。若甲方人员执意执行此类请求，需由前述甲方指定的主要联系人出具书面或邮件确认书，乙方可按照甲方要求执行，但乙方不承担由此带来的任何经济和法律责任。
              </span>
            </div>
          </div>
        </div>

        <div class="layer15 flex-col" style="border-left: 4px solid rgba(235, 235, 235, 1);top: 170px;left: 28px;border-right: 4px solid rgba(235, 235, 235, 1)"><span style="margin-left: 41%;" class="word28">生意参谋采集配置</span></div>
        <div class="layer16 flex-col" style="top: 80px;">
          <div class="box25 flex-col">
            <span class="word29">采集模式</span>
            <span class="word30">部署方式</span>
          </div>
        </div>
        <span class="infoBox4">
          单账号单次持续采集时间不超过2小时，除市场/竞争、其他模块完整采集需要约2个半小时
          <br />
        </span>
      </div>



      <div class="box26 flex-col">
        <div class="mod8 flex-col">
          <div class="layer17 flex-row"><span class="info12">部署步骤</span></div>
          <div class="layer18 flex-row">
            <span class="info13">01</span>
            <span class="info14">02</span>
            <span class="word31">03</span>
            <span class="word32">04</span>
            <span class="info15">05</span>
            <span class="word33">06</span>
          </div>
          <div class="layer19 flex-row">
            <div class="box27 flex-col">
              <div class="main7 flex-col"></div>
              <img
                class="label1"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPngc792158f8279d6beeefa2d3e774b767211bcd78394e5c7de1fad97f5f0f8c2b9.png"
              />
            </div>
            <div class="box28 flex-col"></div>
            <div class="box29 flex-col">
              <div class="main8 flex-col"></div>
              <img
                class="icon2"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng1a72b0233a7d633853e03b32487189e0f8d59e122d67d6005a490316530e7331.png"
              />
            </div>
            <div class="box30 flex-col"></div>
            <div class="box31 flex-col">
              <div class="outer6 flex-col"></div>
              <img
                class="icon3"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng6527c59b0e32c75dff504614cb65a4f0dc1c760f48018fb7c32f3c826d70fc90.png"
              />
            </div>
            <div class="box32 flex-col"></div>
            <div class="box33 flex-col">
              <div class="outer7 flex-col"></div>
              <img
                class="icon4"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng4211a91a1a26938719ac6e560f47facdd35e94ae8233f7982d8905e0fc9fe868.png"
              />
            </div>
            <div class="box34 flex-col"></div>
            <div class="box35 flex-col">
              <div class="main9 flex-col"></div>
              <img
                class="label2"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPngc47f1ae9de19fdb1f7fef5405e5f3edd81c7569f3aab337aeac4d3f5ae9d2137.png"
              />
            </div>
            <div class="box36 flex-col"></div>
            <div class="box37 flex-col">
              <div class="section8 flex-col"></div>
              <img
                class="icon5"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng532ecbaa2e29accf3a12ffc786b484906f02c8a53105023dcce210dd720030db.png"
              />
            </div>
          </div>
          <div class="layer20 flex-row">
            <span class="word34">设置店铺</span>
            <span class="txt11">安装自动化工具包</span>
            <span class="word35">配置数据模块</span>
            <span class="word36">任务编排</span>
            <span class="info16">验证完成部署成功</span>
            <span class="word37">采集数据</span>
          </div>
        </div>
      </div>
      <div class="box38 flex-col"></div>
      <div class="box39 flex-col"><span class="word38">配置需求</span></div>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      constants: {}
    };
  },
  mounted () {
    //&nbsp;切换页面时滚动条自动滚动到顶部
    window.scrollTo(0, 0)
    document.body.scrollTop = document.documentElement.scrollTop = 0
    const element = this.getScrollParent(this.$el)
    element.scrollTop = 0
  },
  methods: {
    // 这个方法通过递归找到滚动的元素,用于置顶页面
    getScrollParent (node) {
      if (node == null) {
        return null
      }
      if (node.scrollHeight > node.clientHeight) {
        return node
      } else {
        return this.getScrollParent(node.parentNode)
      }
    }
  }
};
</script>
<style scoped lang="css" src="./assets/index.css" />
