<template>
  <div id="app" style="background-color: #f3f5f9;">


    <div class="bd1 flex-col"  >

      <div class="wrap1 flex-row justify-between"  style="cursor:pointer;" @click="goHome">
        <img
            class="icon1"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng7a43546e6d4b45ae295795a0568cfdaeb410fd0b336e4f1eae0187d2319e9e1e.png"
        />
        <span class="info1">云扩流程银行</span>
      </div>

    </div>

    <div class="container">
      <!--标题-->
      <div class="page-header">
        <p style="font-size: 22px;font-family: PingFangSC-Semibold, sans-serif;display: block;float: left;margin-left:5px;line-height: 35px;">{{pageData.name}}</p>
        <button class="el-tag--plain el-icon-share btn-share" @click="copyLink">分享</button>
        <button class="el-tag--plain el-icon-back btn-back" style="margin-left:auto;" @click="back">返回</button>
        <!-- <div
            style="width: 65px;height:100%;float: right;margin-left: auto;cursor:pointer;display:flex;align-items: center;" @click="back">
          <img src="../../../public/static/image/back.png" style="float: left;margin-top: 2px;" height="9" width="12"/>
          <p style="color: #6A99F9;float: left;font-size: 12px;margin-left: 3px;">返回</p>
        </div> -->

      </div>
      <!--内容-->
      <div class="page-left">
        <p style="font-family: PingFangSC-Semibold;font-size: 16px;display: block;border-left: solid #3377FD  4px;margin-left: 10px;margin-top: 10px;">
          &nbsp;观看视频</p>
        <div style="border: solid red 0px;width: 96%;height: 255px;margin-top: 5px;margin-left: 10px;">
          <vue-aliplayer-v2
              :source="video.url"
              ref="VueAliplayerV2"
              :options="options"
          />
        </div>

        <p style="font-family: PingFangSC-Semibold;font-size: 16px;;display: block;border-left: solid #3377FD  4px;margin-left: 10px;margin-top: 50px;">
          &nbsp;流程介绍</p>
        <div style="border: solid red 0px;width: 96%;height: 255px;margin-top: 5px;margin-left: 10px;">

          <el-tag type="success" effect="plain" size="mini" style="margin-left: 8px;margin-top: 20px;">&nbsp;&nbsp;业务场景&nbsp;&nbsp;</el-tag>
          <p style="font-family: PingFangSC-Regular, sans-serif;font-size: 12px;display: block;margin-left: 9px;margin-top: 5px;">
            {{pageData.scenario}}
          </p>
          <el-tag type="info" size="mini" effect="plain" style="margin-left: 8px;margin-top: 20px;">&nbsp;&nbsp;业务挑战&nbsp;&nbsp;</el-tag>
          <p style="font-family: PingFangSC-Regular, sans-serif;font-size: 12px;display: block;margin-left: 9px;margin-top: 5px;">
            {{pageData.challenge}}
          </p>
          <el-tag type="danger" size="mini" effect="plain" style="margin-left: 8px;margin-top: 20px;">&nbsp;&nbsp;解决方案&nbsp;&nbsp;</el-tag>
          <p style="font-family: PingFangSC-Regular, sans-serif;font-size: 12px;display: block;margin-left: 9px;
           white-space: pre-wrap;margin-top: 5px;" v-html="pageData.solution">
          </p>
        </div>

      </div>
      <div class="page-right">

        <div style="border: solid red 0px;width: 95%;height:120px;margin: 10px auto;background-color: #f3f5f9">

          <br>
          <p style="font-family: PingFangSC-Semibold;font-size: 16px;;display: block;border-left: solid #3377FD  4px;margin-left: 10px;margin-top: 0px;">
            &nbsp;标签</p>

          <div style="border: solid red 0px;width: 97%;height: 25px;float: right;">
            <p style="display: block;margin-top: 5px;margin-left: 8px;float: left;font-size: 12px;">行业</p>
            <el-tag type="success" effect="dark" size="mini" style="float: left;margin-left: 20px;margin-top: 4px;" v-for="item in pageData.flow_industry" :key="item.name">
              &nbsp;&nbsp;{{item.name}}&nbsp;&nbsp;
            </el-tag>

          </div>
          <div style="border: solid red 0px;width: 97%;height: 25px;float: right;">
            <p style="display: block;margin-top: 5px;margin-left: 8px;float: left;font-size: 12px;">职能</p>
            <el-tag type="info" effect="dark" size="mini" style="float: left;margin-left: 20px;margin-top: 4px;" v-for="item in pageData.flow_function" :key="item.name">
              &nbsp;&nbsp;{{item.name}} &nbsp;&nbsp;
            </el-tag>
          </div>
          <div style="border: solid red 0px;width: 97%;height: 25px;float: right;">
            <p style="display: block;margin-top: 5px;margin-left: 8px;float: left;font-size: 12px;">系统</p>
            <el-tag type="warning" effect="dark" size="mini" style="float: left;margin-left: 20px;margin-top: 4px;" v-for="item in pageData.flow_software" :key="item.name">
              &nbsp;&nbsp;{{item.name}} &nbsp;&nbsp;
            </el-tag>
          </div>
        </div>


        <div style="border: solid red 0px;width: 95%;height:162px;margin: 10px auto;background-color: #f3f5f9">
          <br>
          <p style="font-family: PingFangSC-Semibold;font-size: 16px;display: block;border-left: solid #3377FD  4px;margin-left: 10px;margin-top: 0px;">
            &nbsp;客户</p>
          <div v-for="item in pageData.flow_customers" :key="item.id"
              style="border: solid red 0px;width: 40%;height: 25px;float: left;margin-left: 19px;margin-top:5px;font-family: PingFangSC-Regular, sans-serif;font-size: 14px;">
            <p>{{item.customer}}</p>
          </div>

        </div>

        <div class="chat-list">
          <div class="chat-box">
            <p style="font-family: PingFangSC-Semibold;font-size: 14px;font-weight: 700;display: block;margin-left: 15px;margin-top: 7px;">处理时间减少比例</p>
            <div id="chars1" style="border: solid red 0px;width: 85%;height: 100px;margin: 3px auto;"></div>
            <p style="font-family: PingFangSC-Regular, sans-serif;display: block;margin-left: 15px;margin-top: 5px;color: #6699CC ;font-size: 12px;">计算公式:</p>
            <p style="font-family: PingFangSC-Regular, sans-serif;display: block;margin-left: 15px;margin-top: 5px;font-size: 12px;">单次操作时间节约/原操作时间</p>
          </div>

          <div class="chat-box">
            <p style="font-family: PingFangSC-Semibold;font-size: 14px;font-weight: 700;display: block;margin-left: 15px;margin-top: 5px;">ROI</p>
            <div id="chars2" style="border: solid red 0px;width: 85%;height: 100px;margin: 3px auto;"></div>
            <p style="font-family: PingFangSC-Regular, sans-serif;display: block;margin-left: 15px;margin-top: 5px;color: #6699CC ;font-size: 12px;">计算公式:</p>
            <p style="font-family: PingFangSC-Regular, sans-serif;display: block;margin-left: 15px;margin-top: 5px;font-size: 12px;">稳定运转期项目年节约成本/项目投入成本</p>
          </div>

          <div class="chat-box">
            <p style="font-family: PingFangSC-Semibold;font-size: 14px;font-weight: 700;display: block;margin-left: 15px;margin-top: 5px;">流程业务出差错率降低</p>
            <div id="chars3" style="border: solid red 0px;width: 85%;height: 100px;margin: 3px auto;"></div>
            <p style="font-family: PingFangSC-Regular, sans-serif;display: block;margin-left: 15px;margin-top: 7px;color: #6699CC ;font-size: 12px;">计算公式:</p>
            <p style="font-family: PingFangSC-Regular, sans-serif;display: block;margin-left: 15px;margin-top: 5px;font-size: 12px;">实施后流程出错率降低/原出错率</p>
          </div>

          <div class="chat-box">
            <p style="font-family: PingFangSC-Semibold;font-size: 14px;font-weight: 700;display: block;margin-left: 15px;margin-top: 5px;">客户满意度提升</p>
            <div id="chars4" style="border: solid red 0px;width: 85%;height: 100px;margin: 3px auto;"></div>
            <p style="font-family: PingFangSC-Regular, sans-serif;display: block;margin-left: 15px;margin-top: 5px;color: #6699CC ;font-size: 12px;">计算公式:</p>
            <p style="font-family: PingFangSC-Regular;display: block;margin-left: 15px;margin-top: 7px;font-size: 12px;">实施后业务客户满意度提升/原满意度</p>
          </div>
        </div>

      </div>

      <div class="page-bottom">
        <p style="font-family: PingFangSC-Semibold;font-size: 16px;;display: block;border-left: solid #3377FD  4px;margin-left: 10px;margin-top: 10px;">
          &nbsp;相似流程</p>

        <el-card class="box-card"  @click.n.native="clickHref(item.id)"
                 :body-style="{ padding: '0px' }"
                 v-for="(item,index) in pageData.likeList" :key="index" >
          <img :src="item.img_url" style="width: 100%;height: 120px;">
          <div style="text-align: center;">
            <span style="font-size: 14px;">{{item.name}}</span>
          </div>
          <p style="position: absolute;top:10px;left:10px;color: white;font-size: 14px;">{{item.functionName}}</p>
        </el-card>

      </div>

    </div>

  </div>
</template>
<script>
import * as echarts from 'echarts'
import { queryFlowInfo } from '@/js/api/flow.js'
export default {
  data () {
    return {
      html: '',
      constants: {},
      pageData:{
        id:0,
        name:'',
        scenario:'',
        challenge:'',
        solution:'',
        flow_customers:[],
        flow_industry:[],
        flow_function:[],
        flow_software:[],
        TimeReduceRatio:0,//处理时间减少比例
        ErrorRatioDec:0,//流程业务出差错率降低
        SatisfactionInc:0,//客户满意度提升
        ROI:0,
        likeList:[]
      },
      //视频播放组件
      video: {
        url: ''
      },
      options:{
        "width": "100%",
        "autoplay": false,
        "isLive": false,
        "rePlay": false,
        "playsinline": true,
        "preload": true,
        "controlBarVisibility": "hover",
        "useH5Prism": true,
         cover: '',

      },
      playerOptions: {
        fluid: true,
        sources: [
          {
            'src': ''
          }
        ]
      }
    }
  },


  mounted () {
    //&nbsp;切换页面时滚动条自动滚动到顶部
    window.scrollTo(0, 0)
    document.body.scrollTop = document.documentElement.scrollTop = 0
    const element = this.getScrollParent(this.$el)
    element.scrollTop = 0
    parent.scrollTo(0,0);
  },
  created () {
    queryFlowInfo({id:this.$route.query.id}).then(response => {
    let obj=response['data']['data']['flow']
      this.playerOptions.sources=[{src:obj['video_url']}]
      this.video.url=obj['video_url']
      if(obj['video_url']){
        this.options.cover=''
      }else{
        this.options.cover='/static/image/null.jpeg'
      }
      this.pageData.id=parseInt(obj['id'])
      this.pageData.name=obj['name']
      this.pageData.scenario=obj['scenario']
      this.pageData.challenge=obj['challenge']
      this.pageData.solution=obj['solution']
      this.pageData.flow_customers=response['data']['data']['flow_customers']

      this.pageData.flow_industry=response['data']['data']['flow_industry']
      this.pageData.flow_function=response['data']['data']['flow_function']
      this.pageData.flow_software=response['data']['data']['flow_software']

      this.pageData.TimeReduceRatio=response['data']['data']['TimeReduceRatio']
      this.pageData.ErrorRatioDec=response['data']['data']['ErrorRatioDec']
      this.pageData.SatisfactionInc=response['data']['data']['SatisfactionInc']
      this.pageData.ROI=response['data']['data']['ROI']
      this.pageData.likeList=response['data']['data']['likeList']
      if(this.pageData.likeList.length>4){
       this.pageData.likeList=this.pageData.likeList.slice(0,4)
      }
      this.initChars('chars1',this.pageData.TimeReduceRatio,'#6A99F9')
      this.initChars('chars2',this.pageData.ROI,'#F27D7D')
      this.initChars('chars3',this.pageData.ErrorRatioDec,'#FFA845')
      this.initChars('chars4',this.pageData.SatisfactionInc,'#4BC373')


    })

  },
  watch: {
    '$route' () {
      this.$router.go(0);
    }
  },
  methods: {
    initChars (dom,bfb,color) {
      // 基于准备好的dom，初始化echarts实例
      let myChart = echarts.init(document.getElementById(dom))
      let e = bfb
      let option = {
        title: {
          show: true,
          text: e + '%',
          x: 'center',
          y: 'center',// 通过x,y将标题(进度)定位在圆环中心
          textStyle: {
            fontSize: '16',
            color: 'black',
            fontWeight: '400',
            fontFamily: 'DINPro, DINPro-Regular',
          },
        },
        tooltip: {
          trigger: 'item',
          formatter: '{d}%',
          show: false,
        },
        legend: {
          orient: 'vertical',
          x: 'left',
          show: false,
        },
        series: {
          name: '',
          type: 'pie',
          radius: ['65%', '85%'],
          avoidLabelOverlap: true,
          hoverAnimation: false,
          label: {
            normal: {
              show: false,
              position: 'center',
            },
            emphasis: {
              show: false,
            },
          },
          labelLine: {
            normal: {
              show: false,
            },
          },
          data: [
            {
              value: e,
              name: '',
              itemStyle: {
                color: color,
              },
            },
            {
              value: 100 - e,
              name: '',
              itemStyle: {
                color: 'transparent',
              },
            },
          ],
        },
      }
      myChart.setOption(option)

    },
    back(){
      //window.history.back();
      this.$router.back()
    },
    clickHref (id) {

      this.$router.push('/flow_info?id='+id)

    },
    goHome(){
      this.$router.push('/flow_index')
    },
    getScrollParent (node) {
      if (node == null) {
        return null
      }
      if (node.scrollHeight > node.clientHeight) {
        return node
      } else {
        return this.getScrollParent(node.parentNode)
      }
    },
    async copyLink(){
      console.log('url',window.location.href)
      var cInput = document.createElement("input");
      cInput.value = window.location.href;
      document.body.appendChild(cInput);
      cInput.select(); // 选取文本框内容
      document.execCommand("copy");
      this.$message({
        type: "success",
        message: "分享链接复制成功"
      });
      document.body.removeChild(cInput);
    },
  }
}
</script>

<style scoped lang="css" src="./../../assets/flow/common.css"/>
<style scoped lang="css" src="./../../assets/flow/page.css"/>
<style scoped lang="css" src="./assets/index.css"/>

