<template>
    <div id="app">
      <div style="width: 200px;height: 800px;border: solid 1px red;">
        <el-tree  :data="data" :props="defaultProps" @node-click="handleNodeClick" style="" :highlight-current="false"></el-tree>
      </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
      data: [{
        label: '一级 1',
        children: [{
          label: '二级 1-1',
          children: [{
            label: '三级 1-1-1'
          }]
        }]
      }, {
        label: '一级 2',
        children: [{
          label: '二级 2-1',
          children: [{
            label: '三级 2-1-1'
          }]
        }, {
          label: '二级 2-2',
          children: [{
            label: '三级 2-2-1'
          }]
        }]
      }, {
        label: '一级 3',
        children: [{
          label: '二级 3-1',
          children: [{
            label: '三级 3-1-1'
          }]
        }, {
          label: '二级 3-2',
          children: [{
            label: '三级 3-2-1'
          }]
        }]
      }],
      defaultProps: {
        children: 'children',
        label: 'label'
      }
    };
  },
  methods: {
    handleNodeClick(data) {
      console.log(data);
    }
  }
};
</script>

<style scoped>
/deep/ .el-tree-node__content:hover {

  background: white;

}

/deep/.el-tree--highlight-current

.el-tree-node.is-current

> .el-tree-node__content {

  background-color:  white;

}



/deep/ .el-tree-node:focus > .el-tree-node__content {

  background-color:white;

}


</style>
