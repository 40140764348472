<template>
  <div class="page flex-col" style="margin: 0 auto;">
    <div class="wrap1 flex-col">
      <div class="outer1 flex-col">
        <div class="mod1 flex-row">
          <span class="word1">电商生意方舟</span>

          <span class="txt1"><a href="/">首页</a></span>
          <span class="txt2"><a href="/scheme">方案</a></span>
          <span class="word2"><a href="/price" style="border-bottom: solid 1px white;height: 51px;">帮助</a></span>
          <span class="word2"><a href="/dataView">数据全景</a></span>

        </div>
      </div>
      <div class="outer2 flex-col">
        <div class="outer3 flex-row">
          <div class="wrap2 flex-col">
            <div class="block1 flex-col"><span class="word3">1</span></div>
          </div>
          <span class="info1">收费模式：</span>
        </div>
        <div class="outer4 flex-row">
          <div class="box1">
            <span class="word4">·</span>
            <span class="word5" style="font-size: 19px;">&nbsp;按店铺收费</span>
          </div>
        </div>
        <div class="outer5 flex-row">
          <div class="layer1 flex-col">
            <div class="outer6 flex-col"><span class="info2">2</span></div>
          </div>
          <span class="txt3">服务包含：</span>
        </div>
      </div>


      <div class="outer7 flex-col" style="margin-top: 600px">
        <div class="box2 flex-col">
          <span class="word6">微信扫码联系，获取一对一专属顾问</span>
          <div class="box3 flex-col">
            <img
                class="img1"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPngd2d1111db80d008fb74586c5897eaacdc45d8dd7c20929501b30c119b055b3a2.png"
            />
          </div>
          <span class="word7">
            ©&nbsp;2017-2021上海云扩信息科技有限公司版权所有沪ICP备17057328号-2沪公网安备&nbsp;31010602002627号
          </span>
        </div>
      </div>


    </div>
    <div class="wrap3 flex-col">
      <div class="mod2 flex-col">
        <div class="outer8 flex-col">
          <span class="word8">告别重复工作，释放运营双手</span>
          <img
              class="img2"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPnged019c79c7b8c674201674b6fa909293fd21526b0a247a8bcc15374015d2ad40.png"
          />
          <div class="group1 flex-col"></div>
        </div>
      </div>
    </div>
    <div class="wrap4">
      <span class="info3">·</span>
      <span class="paragraph1" style="font-size: 16px;">
        &nbsp;每购买一套为单个店铺，单店铺支持多模块数据选择
        <br/>
      </span>
      <span class="txt4">·</span>
      <span class="paragraph2" style="font-size: 16px;">
        &nbsp;数据内容支持Excel、文件服务、数据库等输出形式
        <br/>
      </span>
      <span class="info4">·</span>
      <span class="infoBox1" style="font-size: 16px;">
        &nbsp;提供自动化部署工具，提供数据库权限和开放API
        <br/>
      </span>
      <span class="info5">·</span>
      <span class="infoBox2" style="font-size: 16px;">
        &nbsp;提供流程通知、报表模版
        <br/>
      </span>
      <span class="word9">·</span>
      <span class="txt5" style="font-size: 16px;">&nbsp;维保范围：自购买产品起一年内的运维服务不另外收取，定制部分除外</span>
    </div>


    <div class="wrap5 flex-col">
      <div class="main1 flex-col">
        <div class="section1 flex-row">
          <div class="box4 flex-col">
            <div class="bd1 flex-col"><span class="txt6">3</span></div>
          </div>
          <span class="info6">服务不包含：</span>
        </div>
        <div class="section2">
          <span class="txt7">·</span>
          <span class="info7" style="font-size: 16px;">&nbsp;采集所需硬件机器（如机器人、服务器）</span>
        </div>
        <div class="section6 flex-col" style="display: none;">
          <div class="wrap6 flex-col">
            <div class="mod3 flex-col"><span class="txt9">价格详情</span></div>
            <div class="mod4 flex-row">
              <span class="txt10">首店铺</span>
              <span class="info9">后续店铺</span>
            </div>
            <div class="mod5 flex-col"></div>
            <div class="mod6 flex-col">
              <div class="layer2 flex-row">
                <span class="word12">模块单价</span>
                <span class="info10">4000元年/单二级模块</span>
                <span class="txt11">1500元年/单二级模块</span>
              </div>
            </div>
            <div class="mod7 flex-col"></div>
            <div class="mod8 flex-row">
              <span class="info11">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;最低采购</span>
              <span class="txt12">5个</span>
              <span class="txt13">5个</span>
            </div>
            <div class="mod9 flex-col"></div>
            <div class="mod10 flex-col">
              <div class="outer9 flex-row">
                <span class="word13">模块报价</span>
                <span class="info12">4000元*N&nbsp;(N为二级模块数量)</span>
                <span class="txt14">1500元*N&nbsp;(N为二级模块数量)</span>
              </div>
            </div>
            <div class="mod11 flex-col"></div>
            <div class="mod12 flex-row">
              <span class="txt15">打包报价</span>
              <span class="info13">包含所有数据模块及后续新增的模块：50+模块=10万元/年</span>
              <span class="word14">若后续店铺增购的模块报价超过3万，统一按3万元/年收费</span>
            </div>
            <div class="mod13 flex-col"></div>
            <div class="mod14 flex-col">
              <div class="main3 flex-row">
                <span class="info14">维保服务</span>
                <span class="word15">
                  包括外部电商平台的界面功能变更/机器人环境变更/服务器迁移/流程运行监控，不含需求/流程逻辑变更。
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="wrap9 flex-col" style="top: 960px;">
      <div class="main4 flex-col">
        <span class="word16">常见问题</span>
        <div class="box5 flex-row">
          <div class="main5 flex-col" v-for="(item, index) in loopData0" :key="index">
            <div class="bd2 flex-col">
              <span class="word17" v-html="item.lanhutext0"></span>
              <span v-if="item.slot2 === 2" class="word18">
                小的页面变更大概一天内完成。&nbsp;大的页面变更大概三天内完成，不影响采集缺失的数据。
              </span>
              <span v-if="item.slot1 === 1" class="word19" style="font-size: 14px;"
                    v-html="item.specialSlot1.lanhutext0"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      loopData0: [
        {
          lanhutext0: 'Q1：云扩提供服务有哪些？',
          specialSlot1: {
            lanhutext0: '环境参数配置服务；推荐运行时间编排；平台安装测试服务；&nbsp;流程优化服务；业务咨询服务等。'
          },
          slot1: 1
        },
        {
          lanhutext0: 'Q2：上线时间大概多久？',
          specialSlot1: { lanhutext0: '大致过程有需求确认，产品安装部署，客户培训及使用。&nbsp;大概1-2周内完成。' },
          slot1: 1
        },
        {
          lanhutext0: 'Q3：是否提供定制开发？',
          specialSlot1: {
            lanhutext0:
                '如果功能模块不满足，可以对客户需求进行调研，定制开发。提供生意方舟标准产品+定制需求功能开发服务。'
          },
          slot1: 1
        },
        {
          lanhutext0: 'Q4：信息安全如何保障？',
          specialSlot1: { lanhutext0: '账号是在控制台保存，提供银行级加密存储。' },
          slot1: 1
        },
        {
          lanhutext0: 'Q5：风险管控能力如何？',
          specialSlot1: { lanhutext0: '对高风险模块每天监控，如果发现风险，立即下架该模块，避免受到反扒警告和处罚。' },
          slot1: 1
        },

        {
          lanhutext0: 'Q6：RPA与爬虫、插件的区别是什么？',
          specialSlot1: { lanhutext0: 'RPA是模拟人的操作，通过技术方式和云扩的经验，能极大降低被反扒和警告的风险。' },
          slot1: 1
        },

        {
          lanhutext0: 'Q7：平台页面变更影响运行怎么办？',
          specialSlot1: {
            lanhutext0: '云扩提供维保服务，会根据电商平台的变更进行快速升级相应，保障数据平台正常使用。'
          },
          slot1: 1
        },

        {
          lanhutext0: 'Q8：电商平台变更，云扩需多久来对应升级产品？',
          specialSlot1: {
            lanhutext0: '小的页面变更大概一天内完成。&nbsp;大的页面变更大概三天内完成，不影响采集缺失的数据。'
          },
          slot1: 1
        },

        {
          lanhutext0: 'Q9：需要为RPA提供单独账号么？',
          specialSlot1: { lanhutext0: '强烈建议为RPA提供单独账号，这样就不会与其他人工或插件互相影响，而触发警告等。' },
          slot1: 1
        },
        {
          lanhutext0: 'Q10：采集完数据的存储格式？',
          specialSlot1: { lanhutext0: '云扩生意方舟支持EXCEL、OSS文件、MySQL。可扩展支持SqlServer、Oracle。' },
          slot1: 1
        },
        {
          lanhutext0: 'Q11：支持哪些BI展现？',
          specialSlot1: {
            lanhutext0: '目前支持开源BI&nbsp;Metabase和Power&nbsp;BI，后续可支持Quick&nbsp;BI、帆软BI。'
          },
          slot1: 1
        },
        {
          lanhutext0: 'Q12：目前有哪些BI报表？',
          specialSlot1: {
            lanhutext0:
                '目前提供核心指标、全店趋势、流量分析、商品排行、人群分析、竟店指标数据、平台盈利指标大盘。后续会提供更多的分析报表，也提供定制化开发。'
          },
          slot1: 1
        },
        {
          lanhutext0: 'Q13：如何规避插件风险与指数转化？',
          specialSlot1: {
            lanhutext0:
                '市场&amp;竞争模块不用阿明工具等插件，用界面自动化或者F12抓包，然后用阿明工具官网或者店透视API来做数据转化，无法转化的数据暂时不做处理。'
          },
          slot1: 1
        },
        {
          lanhutext0: 'Q14：总体费用构成',
          specialSlot1: {
            lanhutext0:
                '客户只需要根据选择的功能模块和店铺数量确认总体费用。' +
                '已包含了云扩维保服务和RPA机器人的费用。客户只需提供运行机器人的电脑或虚拟机。'
          },
          slot1: 1
        }
      ],
      constants: {}
    }
  },
  mounted () {
    //&nbsp;切换页面时滚动条自动滚动到顶部
    window.scrollTo(0, 0)
    document.body.scrollTop = document.documentElement.scrollTop = 0
    const element = this.getScrollParent(this.$el)
    element.scrollTop = 0
  },
  methods: {
    getScrollParent (node) {
      if (node == null) {
        return null
      }
      if (node.scrollHeight > node.clientHeight) {
        return node
      } else {
        return this.getScrollParent(node.parentNode)
      }
    }
  }
}
</script>
<style scoped lang="css" src="./assets/index.css"/>
