<template>

  <div class="page flex-col" style="margin: 0 auto;">
    <div class="bd1 flex-col">
      <div class="outer1 flex-col">
        <div class="mod1 flex-col">
          <div class="outer2 flex-row">
            <span class="word1">电商数据整合平台</span>
            <span class="info1">首页</span>
            <span class="txt1">方案</span>
            <span class="word2">帮助</span>
          </div>
          <div class="outer3 flex-col"></div>
          <span class="txt2">运营团队必备神器，帮您一键升级运营3.0</span>
          <div class="outer4 flex-col">
            <div class="wrap1 flex-row"><span class="word3">粗放式运营</span></div>
            <div class="wrap2 flex-row"><span class="word4">精细化运营</span></div>
            <div class="wrap3 flex-row"><span class="word5">智能化运营</span></div>
          </div>
          <div class="outer5 flex-col">
            <span class="paragraph1">
              依赖手工统计
              <br />
              缺乏方法论，效果欠佳
              <br />
              运营目标，上下不对齐
            </span>
            <span class="word6">1·0</span>
          </div>
          <span class="info2">连接多系统，数据一站管理</span>
          <div class="outer6 flex-row">
            <div class="block1 flex-col">
              <img
                class="pic1"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng4a609c6b4bde75267fe040a448f364a0168444e916aed5ab816883b2f68082ad.png"
              />
            </div>
            <div class="block2 flex-col">
              <img
                class="pic2"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPngd2b0f5e942163db1fe900ec32dbefc514bd49ff1dd79c9d4d869a08c95335636.png"
              />
            </div>
            <div class="block3 flex-col">
              <img
                class="img1"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng161f7431d8717ebdb7b2f7d16db1ec5f3e7526c1597da0acceb6511c924d5047.png"
              />
            </div>
          </div>
          <div class="outer7 flex-row">
            <div class="section1 flex-col">
              <img
                class="img2"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng020bdc8e8ad49055446a2b5c2efb484a80b7098de9227fb3eac9eece3d38eab8.png"
              />
            </div>
            <div class="section2 flex-col">
              <img
                class="pic3"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPngf3faf754c0b3ad8c9ae89b4f3a35d41d67aa3b130e086c770874411e5589769d.png"
              />
            </div>
            <div class="section3 flex-col">
              <img
                class="pic4"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPnga291b2841acd6a7ed22eb9dbcb211fdb09dd3860e8976756af06ca3984a9957a.png"
              />
            </div>
            <div class="section4 flex-col">
              <img
                class="pic5"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng7e41f2c02f12fc1dfdfd78edca8e6044ff3e5ba410b7ea2144833ebbe94b4996.png"
              />
            </div>
          </div>
          <div class="outer8 flex-col" @click="clickUrl('/dataView')" style="cursor:pointer;"><span class="info3">查看全部数据</span></div>
          <span class="info4">敬请期待</span>
          <div class="outer9 flex-row">
            <div class="layer1 flex-col">
              <div class="block4 flex-col">
                <img
                  class="pic6"
                  referrerpolicy="no-referrer"
                  src="./assets/img/SketchPngbd6582f6dc5125abdf9ca96f7c88b70db419ae08e6d854b12fd758f5e8d07e0c.png"
                />
              </div>
            </div>
            <div class="layer2 flex-col">
              <div class="bd2 flex-col">
                <img
                  class="img3"
                  referrerpolicy="no-referrer"
                  src="./assets/img/SketchPng9f2345fec950e124362050b29eaf5fd26f4c88028aeca9161a2b8ad75673161d.png"
                />
                <img
                  class="pic7"
                  referrerpolicy="no-referrer"
                  src="./assets/img/SketchPng8156ddc315df7e4c6759c8d48d4ecd202300f10c10eee837432a5c6e84e6c40e.png"
                />
              </div>
            </div>
            <div class="layer3 flex-col">
              <div class="outer10 flex-col"></div>
              <div class="outer11 flex-col"></div>
            </div>
          </div>
          <div class="outer12 flex-col">
            <div class="mod2 flex-col">
              <div class="wrap4 flex-col">
                <div class="bd3 flex-row">
                  <span class="info5">一次部署，多维收集</span>
                  <div class="group1 flex-col">
                    <img
                      class="label1"
                      referrerpolicy="no-referrer"
                      src="./assets/img/SketchPng48b7e6b2200d2c3173bfb884a082a3a16eb5237d2692725c6e7e52f359246430.png"
                    />
                  </div>
                </div>
                <div class="bd4 flex-row">
                  <div class="box1 flex-col">
                    <img
                      class="label2"
                      referrerpolicy="no-referrer"
                      src="./assets/img/SketchPng0df274cc90e8dd5722c0634630a8c7a8cf17e360729df684b2cb205a444657ff.png"
                    />
                  </div>
                  <div class="box2 flex-col">
                    <img
                      class="icon1"
                      referrerpolicy="no-referrer"
                      src="./assets/img/SketchPngf01b252a50f38176f10dde9c7d99f0b86f18edfade9635a355ff7fe1c7219c2c.png"
                    />
                    <img
                      class="icon2"
                      referrerpolicy="no-referrer"
                      src="./assets/img/SketchPngf01b252a50f38176f10dde9c7d99f0b86f18edfade9635a355ff7fe1c7219c2c.png"
                    />
                  </div>
                  <div class="box3 flex-col">
                    <span class="txt3">交易转化</span>
                    <span class="word7">行业大盘</span>
                  </div>
                  <div class="box4 flex-col">
                    <img
                      class="label3"
                      referrerpolicy="no-referrer"
                      src="./assets/img/SketchPngf01b252a50f38176f10dde9c7d99f0b86f18edfade9635a355ff7fe1c7219c2c.png"
                    />
                    <img
                      class="icon3"
                      referrerpolicy="no-referrer"
                      src="./assets/img/SketchPngf01b252a50f38176f10dde9c7d99f0b86f18edfade9635a355ff7fe1c7219c2c.png"
                    />
                  </div>
                  <div class="box5 flex-col">
                    <span class="word8">竞争分析</span>
                    <span class="txt4">品类罗盘</span>
                  </div>
                  <div class="box6 flex-col">
                    <img
                      class="icon4"
                      referrerpolicy="no-referrer"
                      src="./assets/img/SketchPngf01b252a50f38176f10dde9c7d99f0b86f18edfade9635a355ff7fe1c7219c2c.png"
                    />
                    <img
                      class="icon5"
                      referrerpolicy="no-referrer"
                      src="./assets/img/SketchPngf01b252a50f38176f10dde9c7d99f0b86f18edfade9635a355ff7fe1c7219c2c.png"
                    />
                  </div>
                  <div class="box7 flex-col">
                    <span class="txt5">流量来源</span>
                    <span class="info6">用户画像</span>
                  </div>
                </div>
                <div class="bd5 flex-row">
                  <div class="main1 flex-col"><div class="group2 flex-col"></div></div>
                  <div class="main2 flex-col">
                    <img
                      class="label4"
                      referrerpolicy="no-referrer"
                      src="./assets/img/SketchPngf01b252a50f38176f10dde9c7d99f0b86f18edfade9635a355ff7fe1c7219c2c.png"
                    />
                    <img
                      class="label5"
                      referrerpolicy="no-referrer"
                      src="./assets/img/SketchPngf01b252a50f38176f10dde9c7d99f0b86f18edfade9635a355ff7fe1c7219c2c.png"
                    />
                  </div>
                  <div class="main3 flex-col">
                    <span class="word9">商品分析</span>
                    <span class="word10">内容分析</span>
                  </div>
                  <div class="main4 flex-col">
                    <img
                      class="icon6"
                      referrerpolicy="no-referrer"
                      src="./assets/img/SketchPngf01b252a50f38176f10dde9c7d99f0b86f18edfade9635a355ff7fe1c7219c2c.png"
                    />
                    <img
                      class="label6"
                      referrerpolicy="no-referrer"
                      src="./assets/img/SketchPngf01b252a50f38176f10dde9c7d99f0b86f18edfade9635a355ff7fe1c7219c2c.png"
                    />
                  </div>
                  <div class="main5 flex-col">
                    <span class="word11">搜索排名</span>
                    <span class="word12">产品洞察</span>
                  </div>
                  <div class="main6 flex-col">
                    <img
                      class="label7"
                      referrerpolicy="no-referrer"
                      src="./assets/img/SketchPngf01b252a50f38176f10dde9c7d99f0b86f18edfade9635a355ff7fe1c7219c2c.png"
                    />
                    <img
                      class="icon7"
                      referrerpolicy="no-referrer"
                      src="./assets/img/SketchPngf01b252a50f38176f10dde9c7d99f0b86f18edfade9635a355ff7fe1c7219c2c.png"
                    />
                  </div>
                  <div class="main7 flex-col">
                    <span class="word13">品牌分析</span>
                    <span class="word14">直播监控</span>
                  </div>
                  <div class="main8 flex-col"></div>
                </div>
              </div>
              <div class="wrap5 flex-row">
                <span class="info7">支持多样输出</span>
                <span class="info8">演示案例</span>
                <span class="info9">提供默认报表模版</span>
              </div>
              <div class="wrap6 flex-row">
                <div class="main9 flex-col">
                  <div class="group3 flex-row">
                    <img
                      class="label8"
                      referrerpolicy="no-referrer"
                      src="./assets/img/SketchPngdb697da3bf98069b75c0c931113c4fad6af1070cd0392a950e3fb55146da52d3.png"
                    />
                    <div class="wrap7 flex-col">
                      <img
                        class="icon8"
                        referrerpolicy="no-referrer"
                        src="./assets/img/SketchPngafdca05e3b8c54bfdf96ce80dd5a21874cf28118c5dc1e2a95a30d1c07b5b632.png"
                      />
                    </div>
                    <div class="wrap8 flex-col"><div class="layer4 flex-col"></div></div>
                  </div>
                  <div class="group4 flex-row">
                    <span class="txt6">Excel</span>
                    <span class="txt7">数据库</span>
                    <span class="txt8">文件服务</span>
                  </div>
                  <div class="group5 flex-row">
                    <div class="bd6 flex-col"><div class="mod3 flex-col"></div></div>
                    <div class="bd7 flex-col">
                      <img
                        class="icon9"
                        referrerpolicy="no-referrer"
                        src="./assets/img/SketchPng5399216a900574d4eee0b5d8d5353d006f5724b2b44ed1af551966ad279ab6b6.png"
                      />
                    </div>
                    <div class="bd8 flex-col">
                      <img
                        class="icon10"
                        referrerpolicy="no-referrer"
                        src="./assets/img/SketchPngc20271668c9809d75ffc3f109b0804869273e4dbbc58f1986a0198d67302ff5b.png"
                      />
                    </div>
                  </div>
                  <div class="group6 flex-row">
                    <span class="info10">钉钉</span>
                    <span class="txt9">企业微信</span>
                    <span class="word15">Email</span>
                  </div>
                  <div class="group7 flex-col"></div>
                </div>
                <div class="main10 flex-col">
                  <vueMiniPlayer ref="vueMiniPlayer"  :video="video"   :mutex="true"  />
                </div>
                <div class="main11 flex-col">
                  <div class="layer5 flex-col">
                    <span class="txt10">默认报表</span>
                    <div class="layer6 flex-col"></div>
                  </div>
                </div>
              </div>
              <div class="wrap11 flex-col" @click="clickUrl('/scheme')" style="cursor:pointer">
                <div class="bd9 flex-row">
                  <span class="word16">进一步了解</span>
                  <div class="box8 flex-col"></div>
                </div>
              </div>
            </div>
            <div class="mod4 flex-col">
              <img
                class="icon11"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng5b399db859e4edc2f1d67ccca3f2f38fe0163e1e70e4536aade18ea906fd9ec3.png"
              />
            </div>
            <div class="mod5 flex-col">
              <img
                class="icon12"
                referrerpolicy="no-referrer"
                src="./assets/img/SketchPng5682f26c4fe8068a054e2f59e388bf6490606e7a95748081f9f45df98098dc87.png"
              />
            </div>
          </div>
          <span class="word17">我们的客户</span>
        </div>
        <div class="mod6 flex-col">
          <div class="section6 flex-row">
            <span class="info11">电商生意方舟</span>

            <span class="info12"><a href="/" style="border-bottom: solid 1px white;height: 51px;">首页</a></span>

            <span class="txt11"><a href="/scheme">方案</a></span>

            <span class="word18"><a href="/price">帮助</a></span>

            <span class="word18"><a href="/dataView">数据全景</a></span>




          </div>
        </div>
        <div class="mod7 flex-col">
          <div class="box9 flex-col">
            <span class="paragraph2">
              一站式电商数据运营平台
              <br />
              您的专属智能数据管家
            </span>
            <div class="bd10 flex-row">
              <div class="group8 flex-col" style="cursor:pointer;" @click="trial"><span class="word19">免费试用</span></div>
              <div class="group9 flex-col" style="cursor:pointer;" @click="look"><span class="info13">立即观看</span></div>
            </div>
          </div>
          <img
            class="img4"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng1ade467e9696bd1b055c16f74e94a72ca5f9fbdcbc13d324ae2461d2f440b459.png"
          />
          <div class="box10 flex-col"><div class="section7 flex-col"></div></div>
        </div>
        <div class="mod8 flex-col">
          <div class="main12 flex-col">
            <span class="word20">3·0</span>
            <span class="infoBox1">
              多维数据，即刻洞见
              <br />
              内外部数据，一站管理
              <br />
              7*24无人值守，释放人力
            </span>
          </div>
        </div>
        <div class="mod9 flex-col">
          <div class="section8 flex-col">
            <span class="info14">2·0</span>
            <span class="paragraph3">
              数据挖掘，难度递增
              <br />
              跨站点数据，同步滞后
              <br />
              精细运营，用人成本高
            </span>
          </div>
        </div>
        <div class="mod10 flex-col">
          <div class="outer13 flex-row"><span class="word21">八大功能优势</span></div>
          <div class="outer14 flex-row"><div class="layer7 flex-col"></div></div>
          <div class="outer15 flex-row">
            <div class="bd11 flex-col">
              <div class="outer16 flex-col"></div>
              <div class="outer17 flex-col"><span class="word22">多模块采集统一编排</span></div>
              <div class="outer18 flex-col"><span class="word23">自动上报数据日志</span></div>
            </div>
            <div class="bd12 flex-col"></div>
            <div class="bd13 flex-col">
              <div class="block5 flex-col"></div>
              <div class="block6 flex-col"><span class="info15">界面可视化便于操作</span></div>
              <div class="block7 flex-col"></div>
            </div>
          </div>
          <div class="outer19 flex-row"><div class="group10 flex-col"></div></div>
          <span class="word24">界面元素变化平滑升级</span>
          <span class="word25">数据资产银行级加密处理</span>
          <div class="outer20 flex-col"></div>
          <div class="outer21 flex-col">
            <div class="outer22 flex-col">
              <span class="txt12">标准化部部署快速上线</span>
              <div class="mod11 flex-col">
                <div class="group11 flex-row">
                  <img
                    class="label9"
                    referrerpolicy="no-referrer"
                    src="./assets/img/SketchPng4dd82887901fb45dbcff825b4c5ed643eafa0b669d33b4b7eddad298a3c6ce1e.png"
                  />
                </div>
                <div class="group12 flex-row"><div class="layer8 flex-col"></div></div>
                <div class="group13 flex-row">
                  <img
                    class="label10"
                    referrerpolicy="no-referrer"
                    src="./assets/img/SketchPnge913cb8251979134a077472b5df56a58c9abb7e1b4276293815cfdcf6a523b63.png"
                  />
                </div>
              </div>
              <span class="txt13">流程运行智能通知</span>
            </div>
            <img
              class="label11"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng4528a933d9bc5da1470e8df50d099ab417d423ccf3ebfee39a52a3f66d8aee33.png"
            />
            <div class="outer23 flex-col">
              <span class="infoBox2">
                云扩超自动化
                <br />
                电商数据整合平台
              </span>
            </div>
            <div class="outer24 flex-col">
              <div class="bd14 flex-row">
                <img
                  class="label12"
                  referrerpolicy="no-referrer"
                  src="./assets/img/SketchPng149b39ede53753a625c7dfdac076f962ba7e466172435c35cc0f537efcaa815b.png"
                />
              </div>
              <div class="bd15 flex-row"><div class="section9 flex-col"></div></div>
              <div class="bd16 flex-row">
                <img
                  class="icon13"
                  referrerpolicy="no-referrer"
                  src="./assets/img/SketchPng8be784718956d272c7426849b85a3ecb37e5ecb3049553967df1859286e2da22.png"
                />
              </div>
            </div>
            <div class="outer25 flex-col"></div>
          </div>
          <span class="word26">海量数据精准挖掘</span>
        </div>
        <div class="mod12 flex-col"></div>
      </div>
      <div class="outer26 flex-col">
        <div class="block8 flex-col">
          <span class="info16">微信扫码联系，获取一对一专属顾问</span>
          <div class="section10 flex-col">
            <img
              class="pic9"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngd2d1111db80d008fb74586c5897eaacdc45d8dd7c20929501b30c119b055b3a2.png"
            />
          </div>
          <span class="info17">
            ©&nbsp;2017-2021上海云扩信息科技有限公司版权所有沪ICP备17057328号-2沪公网安备&nbsp;31010602002627号
          </span>
        </div>
      </div>
    </div>
    <div class="bd17 flex-col">
      <div class="block9 flex-col">
        <img
          class="img5"
          referrerpolicy="no-referrer"
          src="./assets/img/SketchPng3ef376eed2e9d973b824e492398669b78e65f8929a1c91960b89ece745aa85f7.png"
        />
      </div>
    </div>
    <div class="bd18 flex-col">
      <img
        class="label13"
        referrerpolicy="no-referrer"
        src="./assets/img/SketchPng50e7b2a6482eb5825abc916351940152ebaf0badb88280663042d0a584d41a46.png"
      />
    </div>
    <div class="bd19 flex-col">
      <img
        class="pic10"
        referrerpolicy="no-referrer"
        src="./assets/img/SketchPng91d070cf1f57171c78da4cce0e60bdfe1986d4c7dbb3df1815c8b7a1e827ac60.png"
      />
    </div>
    <div class="bd20 flex-col">
      <img
        class="img6"
        referrerpolicy="no-referrer"
        src="./assets/img/SketchPng97a3b127eb445395806bf068818b55050c7e0a63f13fe00796d98cab178b9b4a.png"
      />
    </div>
    <div class="bd21 flex-col"><div class="box11 flex-col"></div></div>
    <div class="bd22 flex-col">
      <div class="layer9 flex-row">
        <div class="layer10 flex-col">
          <img
            class="pic11"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPngeaadf833c0f0e4a71fddc4b5e426f62b67ffbe85f225d7ffe7186c131da1240c.png"
          />
        </div>
        <div class="layer11 flex-col">
          <img
            class="img7"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng35d12c59ac277336be6b82db5e7fe32de8518416cfb42f12879af351f768ad6c.png"
          />
        </div>
        <div class="layer12 flex-col">
          <img
            class="img8"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng036a714dee8aed004b02ce65ce4caea1d91f02c12f28d560ea32b5c646733c78.png"
          />
        </div>
        <div class="layer13 flex-col"><div class="bd23 flex-col"></div></div>
        <div class="layer14 flex-col"><div class="box12 flex-col"></div></div>
      </div>
      <div class="layer15 flex-row">
        <div class="section11 flex-col">
          <img
            class="pic12"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPngc6e39f6a3685c92bda050c9b185a29bccecfff8d729937cb926a5e8dccbc3602.png"
          />
        </div>
        <div class="section12 flex-col">
          <img
            class="pic13"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPngd48ace23537a676af53f817f9e864ddd7b0ac728cce09e3756356ac8493b1b31.png"
          />
        </div>
        <div class="section13 flex-col">
          <img
            class="icon14"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng4ba3720062a4548b8a724b29f997e20fdf53341f9e21685f89fca5fd97fb683c.png"
          />
        </div>
        <div class="section14 flex-col"><div class="layer16 flex-col"></div></div>
        <div class="section15 flex-col"><div class="section16 flex-col"></div></div>
      </div>
      <div class="layer17 flex-row">
        <div class="layer18 flex-col"><div class="outer27 flex-col"></div></div>
        <div class="layer19 flex-col"><div class="block10 flex-col"></div></div>
        <div class="layer20 flex-col"></div>
        <div class="layer21 flex-col"><div class="outer28 flex-col"></div></div>
        <div class="layer22 flex-col"><div class="mod13 flex-col"></div></div>
      </div>
      <div class="layer23 flex-row">
        <div class="group14 flex-col">
          <img
            class="img9"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPng6250c554e9c19c7be1fd3df1e3823d356e27ae2d94787925b5dc1dfe494d05a4.png"
          />
        </div>
        <div class="group15 flex-col">
          <img
            class="pic14"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPngca144a4a9f4ffa0ac5f7cf00f3efabf6d08b521384f66962e7ebe9a4c0dad49a.png"
          />
        </div>
        <div class="group16 flex-col">
          <img
            class="pic15"
            referrerpolicy="no-referrer"
            src="./assets/img/SketchPngc0bd77a7cbb85c3e30457ab582a1f9df2d90f5d491762bfc1a442cb7ca5d43d6.png"
          />
        </div>
        <div class="group17 flex-col"><div class="outer29 flex-col"></div></div>
        <div class="group18 flex-col"><div class="bd24 flex-col"></div></div>
      </div>
      <div class="layer24 flex-row">
        <div class="bd25 flex-col"><div class="outer30 flex-col"></div></div>
        <div class="bd26 flex-col"><div class="group19 flex-col"></div></div>
        <div class="bd27 flex-col"><div class="main13 flex-col"></div></div>
        <div class="bd28 flex-col"><div class="wrap12 flex-col"></div></div>
        <div class="bd29 flex-col"><div class="section17 flex-col"></div></div>
      </div>
      <div class="layer25 flex-row">
        <div class="layer26 flex-col"><div class="layer27 flex-col"></div></div>
        <div class="layer28 flex-col">
          <div class="section18 flex-col">
            <img
              class="pic16"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngd13dab762d1bf4425792afff924db8a57fd4095f82918cb90c970c133d521eaa.png"
            />
          </div>
        </div>
        <div class="layer29 flex-col">
          <div class="bd30 flex-col">
            <img
              class="img10"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng17baf215d27e8bbfbf4c82b02ce841f5dc9ac3a93fb48027b5fdcd905daa02b5.png"
            />
          </div>
        </div>
        <div class="layer30 flex-col">
          <div class="section19 flex-col">
            <img
              class="img11"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng970a1ad1a1f770705548de3171ade063ef2d434275bd21e0f496dc38b8a067bf.png"
            />
          </div>
        </div>
        <div class="layer31 flex-col">
          <div class="main14 flex-col">
            <img
              class="img12"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPngcefdd29d956b441b0440c866d1644a38df89a05bd4075ae4984294b3a1c70a8c.png"
            />
          </div>
        </div>
      </div>
      <img
        class="pic17"
        referrerpolicy="no-referrer"
        src="./assets/img/SketchPngf8ef86e6dc77e7501a4419dd84f69988ec7136373dfb9731bf52eaf7841ec659.png"
      />


    </div>


    <!--视频文件-->
    <el-dialog
        title=""
        :visible.sync="dialogVisible"
        :modal-append-to-body="false"
        :show-close="true"
        center
        width="687px">
      <div style="border: solid 0px red;width: 640px;height: 450px;">
        <vueMiniPlayer ref="vueMiniPlayer"  :video="video"   :mutex="true"  />
      </div>

    </el-dialog>

    <!--客服微信-->
    <el-dialog
        :visible.sync="wxdialogVisible"
        :modal-append-to-body="false"
        :show-close="true"
        width="450px"
        center>
      <div style="border: solid 0px red;width: 400px;height: 400px;">
        <div style="width: 100%;height: 38px;border: solid 0px red;margin: 0 auto;" align="center">
          <img src="/static/image/wx_view/wx_head.png" style="margin: 0 auto;">
        </div>
        <div style="width: 100%;height: 40px;border: solid 0px red;margin: 0 auto;" align="center">
          <p style="font-family: PingFangSC-Semibold;font-size: 18px;color: rgba(61, 73, 102, 1);">请微信扫码联系，获取一对一专属顾问</p>
        </div>
        <div style="width: 250px;height: 250px;border: solid 1px rgba(61, 73, 102, 1);margin: 0 auto;" align="center">
          <img style="width: 230px;height: 230px;box-shadow: none;border: solid 0px rgba(61, 73, 102, 1);margin-left: 0px;margin-top: 10px;" src="/static/image/wx_view/wx.png">
        </div>
      </div>
    </el-dialog>

  </div>

</template>
<script>



export default {
  data() {
    return {
      constants: {},
      video: {
        url: 'https://application123456.oss-cn-beijing.aliyuncs.com/%E4%BA%91%E6%89%A9_%E7%94%B5%E5%95%86%E7%94%9F%E6%84%8F%E6%96%B9%E8%88%9F/%E9%A6%96%E9%A1%B5.mp4'
      },
      //视频播放组件
      playerOptions: {
        fluid: true,
        sources: [
          {
            'src': 'https://application123456.oss-cn-beijing.aliyuncs.com/%E4%BA%91%E6%89%A9_%E7%94%B5%E5%95%86%E7%94%9F%E6%84%8F%E6%96%B9%E8%88%9F/%E9%A6%96%E9%A1%B5.mp4'
          }
        ]
      },
      dialogVisible: false,
      wxdialogVisible:false
    };
  },
  components: {

  },
  mounted () {
    window.scrollTo(0,0);
    document.documentElement.style.overflow = 'hidden'; //隐藏横竖滚动条
  },
  methods: {
    clickUrl(url){
      this.$router.push(url);
    },
    //免费试用
    trial(){
      this.wxdialogVisible=true
    },
    look(){
      this.dialogVisible=true
    }
  }
};
</script>
<style>
#login_BG{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
</style>
<style scoped lang="css" src="./assets/index.css" />

