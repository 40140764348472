<template>
  <div>
      <h1>test</h1>
  </div>
</template>

<script>
export default {
  name: 'table'
}
</script>

<style scoped>

</style>
