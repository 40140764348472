
//获得json数据

import axios from 'axios'

export  function  getJson () {
  return  axios.get('/static/data.json')
}

export function getLeftMenu () {
  return axios.get('/static/leftMenu.json')
}
