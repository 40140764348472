<script src="../../js/api/filter.js"></script>
<template>
  <div class="contents">

    <!--首页头部开始-->
    <div class="layer1 flex-col">
      <div class="section1 flex-col">
        <div class="mod1 flex-col justify-between">
          <div class="block1 flex-col">
            <div class="box1 flex-row justify-between">
              <img
                  class="label1"
                  referrerpolicy="no-referrer"
                  src="./assets/img/SketchPng7a43546e6d4b45ae295795a0568cfdaeb410fd0b336e4f1eae0187d2319e9e1e.png"
              />
              <span class="word1">云扩流程银行</span>
            </div>
          </div>
          <img
              class="img1"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng2b6222b839177c72521e7bd33e17d2766e341935dc77932ea1c1b075fa4a11b7.png"
          />
        </div>
      </div>
    </div>
    <!--首页头部结束-->
    <div style="border: solid red 0px;width: 1320px;height: 720px;margin: 10px auto;">

      <div style="border: solid red 0px;width: 300px;height: 720px;float: left;background-color: white;">

        <div style="border: solid red 0px;width: 300px;height: 720px;background-color: white;">
          <template>
            <el-tabs value="first" style="margin-top: 10px;">

              <el-tab-pane label="行业" name="first" style="font-family: PingFangSC-Regular;">


                <el-tag type="" effect="dark" size="small" @click="reset"  style="border: solid red 0px;float: right;cursor:pointer;">&nbsp;&nbsp;重置筛选条件&nbsp;&nbsp;</el-tag>


                <el-select placeholder="" style="width: 100%;margin-top: 5px;font-size: 12px;" v-model="queryModule.proto_industry_value"
                           @change="selectFlow(false,1,'行业')">
                  <el-option style="margin-left: 15px;" label="全部" value="全部"  />
                  <el-option style="margin-left: 15px;" v-for="item in proto_industry" :key="item.id" :label="item.name"
                             :value="item.id"/>
                </el-select>


              </el-tab-pane>
            </el-tabs>

            <el-tabs value="first" style="margin-top: 10px;">
              <el-tab-pane label="职能" name="first" style="font-family: PingFangSC-Regular;">


                <el-checkbox-group v-model="queryModule.proto_function_value" style="font-size: 14px;" >
                  <el-checkbox style="display: block;float: left;border: solid 0px red;width: 150px; "
                               v-for="item in proto_function" :key="item.id" :label="item.id"
                               @change="selectFlow(false,1,'职能')" :disabled="item.id==-1"
                               size="mini"
                               :value="item.id">{{ item.name }}
                  </el-checkbox>
                </el-checkbox-group>
              </el-tab-pane>
            </el-tabs>

            <el-tabs value="first">
              <el-tab-pane label="系统" name="first" style="font-family: PingFangSC-Regular;">
                <el-checkbox-group v-model="queryModule.proto_software_value">

                  <el-checkbox v-for="item in proto_software" :key="item.id" :label="item.id"
                               @change="selectFlow(false,1,'系统')" :disabled="item.id==-1"
                               style="display: block;float: left;border: solid 0px red;width: 150px; "
                               :value="item.id"   size="small" >{{ item.name }}

                  </el-checkbox>

                </el-checkbox-group>


              </el-tab-pane>
            </el-tabs>
          </template>

        </div>
      </div>


      <div style="border: solid black 0px;width: 1000px;height: 720px;float: left;margin-left: 10px;">


        <div style="border: solid red 0px;width: 300px;height: 30px;margin: 0 auto;float: right;">
          <el-input placeholder="请输入内容" class="input-with-select" v-model="queryModule.queryText" style="width: 285px;">
            <el-button slot="append" icon="el-icon-search" @click="submitQuery" style="margin: 0 auto;"></el-button>
          </el-input>
        </div>


        <div style="border: solid blue 0px;width: 1000px;height: 470px;margin-top: 43px;">

          <el-card @click.n.native="clickHref(item.id)" class="box-card" :body-style="{ padding: '0px' }"
                   style="width: 244px;height: 150px;margin-top: 3px;margin-left: 3px;float: left;position:relative;cursor:pointer;"
                   v-for="(item,index) in flows" :key="index">
            <img
                :src="item.img_url"
                style="width: 244px;height: 120px;">
            <div style="text-align: center;">
              <span style="font-size: 14px;">{{ item.name }}</span>
            </div>
            <p style="position: absolute;top:10px;left:10px;color: white;font-size: 14px;">{{ item.functionName }}</p>
            <p style="position: absolute;top:10px;left:10px;color: white;font-size: 14px;">{{ item.softwareName }}</p>

          </el-card>
          <div style="border: solid blueviolet 0px;width: 800px;height: 50px;margin-top: 15px;float: right;">
            <el-pagination style="float: right; margin-top: 10px;"
                           background
                           layout="total, prev, pager, next"
                           :page-size="12"
                           :pager-count="11"
                           @current-change="currentChange"
                           :current-page.sync="queryModule.currentPage"
                           :total="queryModule.total">
            </el-pagination>
          </div>

        </div>





      </div>

    </div>


  </div>
</template>
<script>
import { queryFilterType } from '@/js/api/filter.js'
import { queryFlow } from '@/js/api/flow.js'

export default {
  data () {
    return {
      proto_industry: [],
      proto_function: [],
      proto_software: [],
      queryModule: {
        proto_industry_value: '全部',
        proto_function_value: [],
        proto_software_value: [],
        queryText: '',
        currentPage: 1,
        total: 0
      },
      flows: []
    }
  },
  methods: {
    getScrollParent (node) {
      if (node == null) {
        return null
      }
      if (node.scrollHeight > node.clientHeight) {
        return node
      } else {
        return this.getScrollParent(node.parentNode)
      }
    },
    reset(){
      this.queryModule.proto_industry_value='全部'
      this.queryModule.proto_function_value=[]
      this.queryModule.proto_software_value=[]
      this.queryFilterType()
    },
    currentChange () {
      this.selectFlow(false, undefined, 'search')
    },
    submitQuery () {
      console.log('submitQuery->' + JSON.stringify(this.queryModule))
      this.selectFlow()

    },
    clickHref (id) {
      this.$router.push('/flow_info?id=' + id)
    },
    queryFilterType () {
      queryFilterType({}).then(response => {
        this.proto_industry = response['data']['data']['proto_industry']
        this.proto_function = response['data']['data']['proto_function']
        this.proto_software = response['data']['data']['proto_software']
        if (this.proto_industry.length > 0) {
          //this.queryModule.proto_industry_value = this.proto_industry[0]['id']
        }
        this.selectFlow(true)
      })

    },
    selectFlow (flag, index, type) {
      if (index) {
        this.queryModule.currentPage = index
      }
      let query = { queryText: this.queryModule.queryText }

      if(this.queryModule.proto_industry_value!='全部'){
        query['proto_industry_value'] = this.queryModule.proto_industry_value
      }
      if (this.queryModule.proto_function_value) {
        query['proto_function_value'] = this.queryModule.proto_function_value.join(',')
      }
      if (this.queryModule.proto_software_value) {
        query['proto_software_value'] = this.queryModule.proto_software_value.join(',')
      }
      query['currentPage'] = this.queryModule.currentPage
      queryFlow(query).then(response => {
        this.flows = response['data']['data']['list']
        this.queryModule.total = response['data']['data']['total']
        if (flag) {
          //this.proto_industry=response['data']['data']['industryList']
          //this.proto_function=response['data']['data']['functionList']
          this.proto_software = response['data']['data']['softwareList']
        } else {
          //查询分类
          if (type === '行业') {
            let query1 = {}
            if(this.queryModule.proto_industry_value!='全部'){
              query1['proto_industry_value'] = this.queryModule.proto_industry_value
            }
            this.queryModule.proto_function_value = []
            this.queryModule.proto_software_value = []
            queryFlow(query1).then(res => {
              this.proto_function = res['data']['data']['functionList']
              this.proto_software = res['data']['data']['softwareList']
            })

          }
          if (type === '职能') {
            let query1 = {}
            let query2 = { proto_industry_value: this.queryModule.proto_industry_value }
            if (this.queryModule.proto_function_value && this.queryModule.proto_function_value.length>0) {
              query1 = { proto_function_value: this.queryModule.proto_function_value.join(',') }
              query2['proto_function_value']=this.queryModule.proto_function_value.join(',')
            }
            this.queryModule.proto_software_value = []
            queryFlow(query1).then(res => {
              this.proto_industry = res['data']['data']['industryList']
            })
            queryFlow(query2).then(res => {
              this.proto_software = res['data']['data']['softwareList']
            })
          }

        }

      })

    }
  },

  mounted () {
    setTimeout(()=>{
      document.getElementsByTagName('iframe')[0].allowFullscreen=true
    },5000)
    //&nbsp;切换页面时滚动条自动滚动到顶部

    document.documentElement.style.overflow = 'hidden'; //隐藏横竖滚动条
    window.scrollTo(0, 0)
    document.body.scrollTop = document.documentElement.scrollTop = 0
    const element = this.getScrollParent(this.$el)
    element.scrollTop = 0
  },

  created () {

    this.queryFilterType()

  }
}
</script>
<style scoped lang="css" src="./../../assets/flow/common.css"/>
<style scoped lang="css" src="./assets/index.css"/>
