import Vue from 'vue'
import VueRouter from 'vue-router'
import customer from '../views/customer/index.vue'
import home from '../views/home/index.vue'
import dataView from '../views/dataView/index.vue'
import scheme from '../views/scheme/page.vue'
import price from '../views/price/page.vue'
import menu from '../views/menu/index.vue'
import demo from '../views/demo/index.vue'
import table from '../views/table/index'
import json from '../views/json/index'
import json1 from '../views/json-dev/index'
import flow_index from '../views/flow_index/page.vue'
import flow_info from '../views/flow_info/page.vue'
import test1 from '../views/back/price/page.vue'
import testtest from '../views/testtest/testtest.vue'
Vue.use(VueRouter)

const routes = [
  //首页
  {
    path: '/',
    redirect: '/home'
  },

  {
    path: '/testtest',
    name: 'testtest',
    component: testtest
  },
  {
    path: '/home',
    name: 'home',
    component: home
  },
  {
    path: '/test1',
    name: 'test1',
    component: test1
  },
  //test
  {
    path: '/table',
    name: 'table',
    component: table
  },
  //微信客服
  {
    path: '/customer',
    name: 'customer',
    component: customer
  },
  //全景图
  {
    path: '/dataView',
    name: 'dataView',
    component: dataView
  },
  //方案
  {
    path: '/scheme',
    name: 'scheme',
    component: scheme
  },
  //价格
  {
    path: '/price',
    name: 'price',
    component: price
  },
  //菜单
  {
    path: '/menu',
    name: 'menu',
    component: menu
  },
  {
    path: '/demo',
    name: 'demo',
    component: demo
  },
  {
    path: '/jsonEdit',
    name: 'json',
    component: json
  },
  {
    path: '/jsonEditDev',
    name: 'json-dev',
    component: json1
  },
  //首页
  {
    path: '/flow_index',
    name: 'flow_index',
    meta: {
      title: '流程银行',
      keepAlive: true // 需要缓存
    },
    component: flow_index
  },
  {
    path: '/flow_info',
    name: 'flow_info',
    component: flow_info,
    meta: {
      title: '流程银行'
    },
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }


})

export default router
