import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import vueMiniPlayer from 'vue-mini-player'
import axios from 'axios'
import 'vue-mini-player/lib/vue-mini-player.css'
import backTop from './views/component/backTop'
import layer from 'vue-layer'

import 'video.js/dist/video-js.css'
import jsonEditor from 'v-jsoneditor'
import VideoPlayer from 'vue-video-player'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
import VueAliplayerV2 from "vue-aliplayer-v2";
import VueSidebarMenu from 'vue-sidebar-menu'




Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(vueMiniPlayer)
Vue.use(VideoPlayer)
Vue.use(jsonEditor)
Vue.use(VueAliplayerV2);
Vue.prototype.axios = axios
Vue.prototype.$layer = layer(Vue);
Vue.use(VueSidebarMenu)
Vue.component('backTop', backTop)

/* 路由发生变化修改页面title */
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
