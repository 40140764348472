<template>
  <div id="app">
    <div id="temp">
      <template>


        <el-select style="margin-top: 10px;margin-left: 10px;" v-model="options.mode" @change="clearOther"
                   placeholder="模式">
          <el-option value="code" label="代码模式"></el-option>
          <el-option value="tree" label="视图模式"></el-option>
          <el-option value="text" label="文本模式"></el-option>
          <el-option value="preview" label="预览模式"></el-option>
          <el-option value="form" label="表单模式"></el-option>
        </el-select>

        <el-button style="margin-top: 10px;margin-left: 10px;" type="primary" @click="getJson">保存数据</el-button>


        <v-jsoneditor style="margin-top: 10px;margin-left: 10px;" ref="editor" v-model="saveData.json"
                      :options="options"
                      height="1000px" width="100%"></v-jsoneditor>
      </template>

    </div>
  </div>
</template>

<script>
import axios from 'axios'
import jsonEditor from 'v-jsoneditor'

export default {
  name: 'app',
  components: {
    jsonEditor
  },
  data () {
    return {
      options: {
        mode: 'code',
        search: true
      },
      saveData: {
        id: 0,
        json: {}
      }
    }
  },
  created () {
    //初始化数据
    let jsonText = this.getQueryString('json')
    let id = this.getQueryString('id')
    this.saveData.id = id
    this.saveData.json = JSON.parse(jsonText)
  },
  mounted () {
    this.clearOther()
  },
  methods: {
    onError () {
      console.log('error')
    },
    getJson () {
      try {
        this.saveData.json = this.$refs.editor.editor.get()
        axios({
          // 请求方式
          method: 'POST',
          // 请求的地址
          url: 'https://baochuan.encoo.com/roboti-dev/model/updateModule',
          // URL 中的查询参数
          data: {
            id: this.saveData.id,
            conf: JSON.stringify(this.saveData.json)
          }
        }).then(() => {
          this.$notify({
            title: '成功',
            message: '修改成功',
            type: 'success',
            position: 'top-left',
            offset: 300
          });
        })
      } catch (e) {
        this.$notify.error({
          title: '错误',
          message: 'JSON格式错误',
          position: 'top-left',
          offset: 300
        });
      }

    },
    getParam (name) {
      const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
      const r = window.location.search.substr(1).match(reg)
      if (r != null) {
        return unescape(r[2])
      }
      return null
    },
    getQueryString (key) {
      let reg = new RegExp('(^|&)' + key + '=([^&]*)(&|$)')
      let result = window.location.search.substr(1).match(reg)
      if (result != null) {
        return decodeURIComponent(result[2])
      } else {
        return ''
      }

    },
    clearOther () {
      let objs = document.getElementsByClassName('jsoneditor-poweredBy')
      if (objs.length > 0) {
        document.getElementsByClassName('jsoneditor-poweredBy')[0].remove()
      }

    },
  }
}
</script>

<style scoped>
.jsoneditor-poweredBy {
  display: none;
}
</style>
