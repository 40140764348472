import request from '@/js/request'


export function queryFilterType(query) {
  return request({
    url: '/filter/queryFilterType',
    method: 'get',
    params: query
  })
}

