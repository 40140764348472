<template>
  <div class="page flex-col" style="margin: 0 auto;">
    <div class="wrap1 flex-col">
      <div class="group1 flex-col">
        <div class="mod1 flex-row">
          <span class="info1">电商生意方舟</span>

          <span class="word1"><a href="/">首页</a></span>
          <span class="txt1"><a href="/scheme">方案</a></span>
          <span class="word2"><a href="/price">帮助</a></span>
          <span class="word2"><a href="/dataView" style="border-bottom: solid 1px white;height: 51px;width: 72px;">数据全景</a></span>


        </div>
      </div>
      <div class="group2 flex-row">
        <span class="word3">生意参谋</span>
        <span class="word4">整合8大模块数据</span>
      </div>
      <div class="group3 flex-row">
        <div class="main1 flex-col" v-for="(item, index) in loopData0" :key="index" @click="hrefClick('生意参谋-'+item.lanhutext0)">
          <div class="group4 flex-row">
            <div class="section1 flex-col">
              <div class="layer1 flex-col" :style="{ background: item.lanhuBg3 }">
                <div class="section2 flex-col"></div>
              </div>
            </div>
            <div class="section3 flex-col">
              <span class="word5" v-html="item.lanhutext0"></span>
              <span class="info2" v-html="item.lanhutext1"></span>
            </div>
          </div>
        </div>
      </div>
      <div class="group5 flex-row">
        <span class="txt2">超级推荐</span>
        <span class="word6">整合2大模块数据</span>
      </div>
      <div class="group6 flex-row">
        <div class="block1 flex-col" @click="hrefClick('超级推荐-账户')">
          <div class="box1 flex-row">
            <div class="layer2 flex-col">
              <div class="layer3 flex-col"><div class="wrap2 flex-col"></div></div>
            </div>
            <div class="layer4 flex-col">
              <span class="info3">账户</span>
              <span class="info4">3大数据</span>
            </div>
          </div>
        </div>
        <div class="block2 flex-col" @click="hrefClick('超级推荐-推广报表')">
          <div class="group7 flex-row">
            <div class="main2 flex-col">
              <div class="main3 flex-col"><div class="outer1 flex-col"></div></div>
            </div>
            <div class="main4 flex-col">
              <span class="info5">推广报表</span>
              <span class="info6">2大数据</span>
            </div>
          </div>
        </div>
      </div>
      <div class="group8 flex-row">
        <span class="word7">超级钻展</span>
        <span class="info7">整合2大模块数据</span>
      </div>
      <div class="group9 flex-row">
        <div class="group10 flex-col" @click="hrefClick('超级钻展-账户')">
          <div class="main5 flex-row">
            <div class="group11 flex-col">
              <div class="block3 flex-col"><div class="layer5 flex-col"></div></div>
            </div>
            <div class="group12 flex-col">
              <span class="info8">账户</span>
              <span class="info9">3大数据</span>
            </div>
          </div>
        </div>
        <div class="group13 flex-col" @click="hrefClick('超级钻展-批量报表')">
          <div class="main6 flex-row">
            <div class="layer6 flex-col">
              <div class="bd1 flex-col"><div class="block4 flex-col"></div></div>
            </div>
            <div class="layer7 flex-col">
              <span class="txt3">批量报表</span>
              <span class="word8">2大数据</span>
            </div>
          </div>
        </div>
      </div>
      <div class="group14 flex-row">
        <span class="info10">品销宝</span>
        <span class="word9">整合2大模块数据</span>
      </div>
      <div class="group15 flex-row">
        <div class="main7 flex-col" @click="hrefClick('品销宝-账户')">
          <div class="bd2 flex-row">
            <div class="box2 flex-col">
              <div class="wrap3 flex-col"><div class="bd3 flex-col"></div></div>
            </div>
            <div class="box3 flex-col">
              <span class="word10">账户</span>
              <span class="txt4">3大数据</span>
            </div>
          </div>
        </div>
        <div class="main8 flex-col" @click="hrefClick('品销宝-报表')">
          <div class="outer2 flex-row">
            <div class="main9 flex-col">
              <div class="main10 flex-col"><div class="main11 flex-col"></div></div>
            </div>
            <div class="main12 flex-col">
              <span class="txt5">报表</span>
              <span class="word11">2大数据</span>
            </div>
          </div>
        </div>
      </div>
      <div class="group16 flex-row">
        <span class="word12">淘宝客</span>
        <span class="word13">整合2大模块数据</span>
      </div>
      <div class="group17 flex-row">
        <div class="box4 flex-col" @click="hrefClick('淘宝客-账号')">
          <div class="bd4 flex-row">
            <div class="outer3 flex-col">
              <div class="box5 flex-col"><div class="layer8 flex-col"></div></div>
            </div>
            <div class="outer4 flex-col">
              <span class="txt6">账号</span>
              <span class="word14">3大数据</span>
            </div>
          </div>
        </div>
        <div class="box6 flex-col" @click="hrefClick('淘宝客-效果报表')">
          <div class="main13 flex-row">
            <div class="mod2 flex-col">
              <div class="mod3 flex-col"><div class="wrap4 flex-col"></div></div>
            </div>
            <div class="mod4 flex-col">
              <span class="info11">效果报表</span>
              <span class="txt7">2大数据</span>
            </div>
          </div>
        </div>
      </div>
      <div class="group18 flex-row">
        <span class="info12">天猫直通车</span>
        <span class="word15">整合2大模块数据</span>
      </div>
      <div class="group19 flex-row">
        <div class="wrap5 flex-col" @click="hrefClick('天猫直通车-账户')">
          <div class="outer5 flex-row">
            <div class="outer6 flex-col">
              <div class="main14 flex-col"><div class="block5 flex-col"></div></div>
            </div>
            <div class="outer7 flex-col">
              <span class="word16">账户</span>
              <span class="txt8">3大数据</span>
            </div>
          </div>
        </div>
        <div class="wrap6 flex-col" @click="hrefClick('天猫直通车-报表')">
          <div class="layer9 flex-row">
            <div class="outer8 flex-col">
              <div class="bd5 flex-col"><div class="outer9 flex-col"></div></div>
            </div>
            <div class="outer10 flex-col">
              <span class="info13">报表</span>
              <span class="word17">2大数据</span>
            </div>
          </div>
        </div>
      </div>
      <div class="group20 flex-row">
        <span class="txt9">万相台</span>
        <span class="txt10">整合3大模块数据</span>
      </div>
      <div class="group21 flex-row">
        <div class="wrap7 flex-col" @click="hrefClick('万相台-账户')">
          <div class="box7 flex-row">
            <div class="group22 flex-col">
              <div class="bd6 flex-col"><div class="block6 flex-col"></div></div>
            </div>
            <div class="group23 flex-col">
              <span class="word18">账户</span>
              <span class="txt11">3大数据</span>
            </div>
          </div>
        </div>
        <div class="wrap8 flex-col" @click="hrefClick('万相台-推广计划')">
          <div class="section4 flex-row">
            <div class="section5 flex-col">
              <div class="layer10 flex-col"><div class="layer11 flex-col"></div></div>
            </div>
            <div class="section6 flex-col">
              <span class="word19">推广计划</span>
              <span class="word20">2大数据</span>
            </div>
          </div>
        </div>
        <div class="wrap9 flex-col" @click="hrefClick('万相台-超级直播(定向)')">
          <div class="main15 flex-row">
            <img
              class="icon1"
              referrerpolicy="no-referrer"
              src="./assets/img/SketchPng3e0a7a7302eeeda589697448a379a6afef22af11985c1fba865559544a0ef3b6.png"
            />
            <div class="group24 flex-col">
              <span class="info14">超级直播(定向)</span>
              <span class="word21">2大数据</span>
            </div>
          </div>
        </div>
      </div>
      <div class="group25 flex-col">
        <div class="section7 flex-col">
          <span class="info15">微信扫码联系，获取一对一专属顾问</span>
          <div class="outer11 flex-col">
            <img
              class="pic1"
              referrerpolicy="no-referrer"
              src="/static/image/wx.png"
            />
          </div>
          <span class="word22">
            ©&nbsp;2017-2021上海云扩信息科技有限公司版权所有沪ICP备17057328号-2沪公网安备&nbsp;31010602002627号
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loopData0: [
        { lanhuBg3: 'rgba(16,140,238,1)', lanhutext0: '作战室', lanhutext1: '核心数据' },
        { lanhuBg3: 'rgba(253,137,1,1)', lanhutext0: '直播', lanhutext1: '2大数据' },
        { lanhuBg3: 'rgba(247,74,72,1)', lanhutext0: '内容', lanhutext1: '4大数据' },
        { lanhuBg3: 'rgba(133,67,223,1)', lanhutext0: '品类', lanhutext1: '4大数据' },
        { lanhuBg3: 'rgba(53,121,252,1)', lanhutext0: '自助分析', lanhutext1: '核心数据' },
        { lanhuBg3: 'rgba(31,46,77,1)', lanhutext0: '流量', lanhutext1: '2大数据' },
        { lanhuBg3: 'rgba(16,140,238,1)', lanhutext0: '竞争', lanhutext1: '5大数据' },
        { lanhuBg3: 'rgba(53,198,37,1)', lanhutext0: '市场', lanhutext1: '2大数据' }
      ],
      constants: {}
    };
  },
  mounted () {
    //&nbsp;切换页面时滚动条自动滚动到顶部
    window.scrollTo(0, 0)
    document.body.scrollTop = document.documentElement.scrollTop = 0
    const element = this.getScrollParent(this.$el)
    element.scrollTop = 0
  },
  methods: {
    hrefClick(parm){
      this.$router.push("/menu?type="+parm);
    },
    getScrollParent (node) {
      if (node == null) {
        return null
      }
      if (node.scrollHeight > node.clientHeight) {
        return node
      } else {
        return this.getScrollParent(node.parentNode)
      }
    }
  }
};
</script>
<style scoped lang="css" src="./assets/index.css" />
